import React, {Component} from "react";

import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';

import {ToastContainer, toast} from 'react-toastify';

import ButtonComponent from './components/ButtonComponent';

import ModalComponent from './components/ModalComponent';

import * as StandardTask from './utilities/StandardTask';

// import profile from '../images/profile/profile.png';
import bg from '../images/DIT-BG-01.png';

class ChangePassword extends Component {

    API_DEFAULT_END_POINT = process.env.REACT_APP_API_END_POINT;
    API_END_POINT = this.API_DEFAULT_END_POINT + "masterbase/monitor-user-account/";

    state = {
        Control: {
            Title: 'เปลี่ยนรหัสผ่าน',  
            Icon: 'fa-solid fa-lock fa-fw',           
            Mode: 'ChangePassword',
            Modal: {Action: 'View', Toggle: false},
            Toast: {Delay: 800}            
        },
        Input: {
            id : {
                id: 'id',
                label: 'รหัสผู้ใช้งาน',
                value: StandardTask.getUserProfile().id,
                validator: {
                    type: 'text',
                    required: true
                },
                error: {status: false, msg: ''}
            },
            oldPassword : {
                id: 'oldPassword',
                label: 'รหัสผ่านเดิม',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 8,
                    maxLength: 50,
                },
                error: {status: false, msg: ''}
            },
            newPassword: {
                id: 'newPassword',
                label: 'รหัสผ่านใหม่',
                value: '',                
                validator: {                    
                    type: 'password',
                    required: true,
                    minLength: 8,
                    maxLength: 50
                },
                error: {status: false, msg: ''}
            },
            confirmPassword: {
                id: 'confirmPassword',
                label: 'ยืนยันรหัสผ่าน',
                value: '',                
                validator: {                    
                    type: 'password',
                    required: true,
                    minLength: 8,
                    maxLength: 50
                },
                error: {status: false, msg: ''}
            }
        },
        FormStatus: false,
        Debug: false
    };
    
    StagingControl = (action, key, value) => { this.setState(prev => {return {[key]: value} }, () => { if(this.state.Debug === true){ console.log( action + " : ", this.state );} });}
    
    FlowControl = (action) => { const ControlElements = StandardTask.FlowController(action, {...this.state.Control}); this.StagingControl('FlowControl - ' + action , 'Control', ControlElements); }

    ModalControl = (action, bl) => {
        let ControlElements = StandardTask.ModalController(action, {...this.state.Control});
        this.setState(prev => {return {Control: ControlElements} }, () => {
            if(this.state.Debug === true){ console.log("ModalClose - " + action + " : ", this.state.Control);}
            if(bl === true){ if(action === 'ChangePassword'){ console.log("ChangePassword");} }        
        });        
    }  

    InputChange = (e) => {
        const result = StandardTask.InputController({...this.state.Input}, e.target.name, e.target.value, true);
        this.setState(prevState => {return {Input: result.InputElements, FormStatus: result.FormStatus}}, () => { if(this.state.Debug === true){ console.log("Input Change : ", this.state.Input); }});         
    } 

    InputToDB = async (mode) => {   

        let method = 'PUT';
        let inputElements = this.state.Input;

        if( inputElements.newPassword.value !== inputElements.confirmPassword.value ){
            StandardTask.Notification(toast, mode, 'not-match', this.state.Control.Title);
        }else{
            const inputs = StandardTask.InputForm({...this.state.Input});
            
            await axios.request({method: method, url: this.API_END_POINT + "check-password/", data: inputs})
            .then((res) => {  
                if(res.data.status){
                    if(res.data.rows > 0 ){
                        axios.request({method: method, url: this.API_END_POINT + "change-password/", data: inputs})
                        .then((res) => {
                            if(res.data.status){
                                StandardTask.Notification(toast, mode, 'success', this.state.Control.Title);

                                inputElements.oldPassword.value = '';
                                inputElements.newPassword.value = '';
                                inputElements.confirmPassword.value = '';

                                this.setState(prev => {return {Input: inputElements} }, () => { if(this.state.Debug === true){ console.log("Password Change : " , this.state.Input ) }});
                            }
                            else{ StandardTask.Notification(toast, mode, 'error', this.state.Control.Title); }
                        })
                        .catch((err) => { if(this.state.Debug === true){console.log("ChangePassword.Error : ", err);} });
                    }
                    else{StandardTask.Notification(toast, mode, 'in-correct', this.state.Control.Title);}
                }else{ StandardTask.Notification(toast, mode, 'error', this.state.Control.Title); }
            }).catch((err) => { if(this.state.Debug === true){console.log("CheckPassword.Error : ", err);} });                
        } 
    }    

    render(){

        let Control = this.state.Control;
        let Input = this.state.Input;  
        
        let size = StandardTask.getElementsSize(); 

        let userProfile = StandardTask.getUserProfile(); 

        return (
            <>

                <ModalComponent modal={Control.Modal} CallBack={(action, bl) => this.ModalControl(action, bl)}></ModalComponent>           
                
                <ToastContainer autoClose={Control.Toast.Delay} closeOnClick className="toast-border-radius" />                   

                <div className="form-head mb-4">
                    <h2 className="text-black font-w400 mb-0">
                        <i className={Control.Icon}></i> {Control.Title}                      
                    </h2>
                </div>             

                <Row>
                    <Col lg={4}></Col>
                    <Col lg={4}>
                        <Card className="overflow-hidden">
                                <div 
                                    className='text-center p-3'
                                    style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', minHeight: '320px'}}
                                >
                                {/** 
                                <div className='profile-photo'>
                                        <img
                                        src={profile}
                                        width='100'
                                        className='m-auto img-fluid rounded-circle d-block'
                                        alt=''
                                        />
                                    </div>
                                    <h3 className='mt-3 mb-1 text-white'>เอศนะ คู่อรุณยสุนทร</h3>
                                    <p className='text-white mb-0'>นักพัฒนาระบบ</p>                                        
                                
                                */}
                            </div>
                            <ul className='list-group list-group-flush'>  
                                <li className='list-group-item d-flex justify-content-between'>
                                    <span className='mb-0'>บัญชีผู้ใช้งาน</span>{' '}
                                    <strong className='text-muted'>{userProfile.username}</strong>
                                </li>      

                                <li className='list-group-item '>
                                    <Row>
                                        <Col lg={12} className="mb-3">                                               
                                            <label htmlFor={Input.oldPassword.id}>{Input.oldPassword.label}</label>
                                            <Form.Control 
                                                type="password" 
                                                id={Input.oldPassword.id} 
                                                name={Input.oldPassword.id} 
                                                placeholder={Input.oldPassword.label}
                                                value={Input.oldPassword.value}
                                                onChange={(e) => this.InputChange(e)}
                                            ></Form.Control> 
                                            <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'oldPassword')}</Form.Control.Feedback> 
                                        </Col>
                                    </Row>
                                </li>  
                                                                                                       
                                <li className='list-group-item '>
                                    <Row>
                                        <Col lg={12} className="mb-3">                                               
                                            <label htmlFor={Input.newPassword.id}>{Input.newPassword.label}</label>
                                            <Form.Control 
                                                type="password" 
                                                id={Input.newPassword.id} 
                                                name={Input.newPassword.id}
                                                placeholder={Input.newPassword.label}
                                                value={Input.newPassword.value}
                                                onChange={(e) => this.InputChange(e)}
                                                size={size}
                                            ></Form.Control> 
                                            <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'newPassword')}</Form.Control.Feedback>   
                                        </Col>
                                    </Row>
                                </li>  

                                <li className='list-group-item '>
                                    <Row>
                                        <Col lg={12} className="mb-3">                                               
                                            <label htmlFor={Input.confirmPassword.id}>{Input.confirmPassword.label}</label>
                                            <Form.Control 
                                                type="password" 
                                                id={Input.confirmPassword.id} 
                                                name={Input.confirmPassword.id} 
                                                placeholder={Input.confirmPassword.label}
                                                value={Input.confirmPassword.value}
                                                onChange={(e) => this.InputChange(e)}
                                                size={size}
                                            >
                                            </Form.Control>  
                                            <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'confirmPassword')}</Form.Control.Feedback>   
                                        </Col>
                                    </Row>
                                </li>   

                            </ul>   
                            <div className='card-footer border-0 mt-0'>
                                <ButtonComponent action="ChangePassword" disabled={!this.state.FormStatus} CallBack={(e) => { this.InputToDB(this.state.Control.Mode);}}></ButtonComponent> 
                            </div>                                         
                        </Card>                                                   
                    </Col>
                    <Col lg={4}></Col>
                </Row>                   
            </>
        );
    }
}
export default ChangePassword;