import React  from 'react';
import DatePicker from 'react-multi-date-picker';

import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";

import thai from "./lang/thai";
import thai_th from "./lang/thai_th"


export default function DatePickerThComponent (props) {

  const { id, CallBack } = props;

  const handleDateChange = (newDates) => {
    CallBack(id, newDates.toString());
  };

  const inputStyle = {
    width: '100%', 
    display: 'block'
  };  

  return (
    <>
        <DatePicker 
            calendar={thai}
            locale={thai_th}
            format="DD/MM/YYYY"
            placeholder="วันที่"
            animations={[
              opacity(), 
              transition({ from: 35, duration: 800 })
            ]} 
            inputClass="form-control form-control-md"
            onChange={handleDateChange}             
            style={inputStyle}    
        />
    </>

  )
}

/** 
class DatePickerThComponent extends Component{

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: ""
    };
  }  

    handleDateChange = (newDates) => {
      console.log(this.props);
      //this.setState({ selectedDates: newDates });
    };

    render(){
      return (
        <>
        {console.log(DatePicker)}
         <DatePicker
            calendar={thai}
            locale={thai_th}
            format="DD/MM/YYYY"
            animations={[
              opacity(), 
              transition({ from: 35, duration: 800 })
            ]} 
            inputClass="form-control form-control-md"
            value="19/09/2566"
            onChange={(e) => this.handleDateChange(this)} 
         ></DatePicker>
        </>
      );
    }
}

export default DatePickerThComponent;
*/