import React , {Component} from "react";

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

import * as StandardTask from '../utilities/StandardTask';

class ButtonDropdownComponent extends Component{

    callback = (val) => { if (this.props.CallBack instanceof Function) { this.props.CallBack(val); } }

    render(){

        let value = this.props.value;
        // let size = this.props.size;
        let dataSet = this.props.dataSet;

        let title;
        let dropdownClass;
        let dataList = [];
        let icon = "";

        let jsxDropdownButton;

        // let jsxStyle = {fontSize: StandardTask.getElementsSize()};
        let size = StandardTask.getElementsSize();
        size = "sm";

        if(dataSet === "status"){ 
            dataList[0] = {id: '0', label: 'ใช้งาน', class: 'success', icon: 'fa-solid fa-circle-check fa-fw'}
            dataList[1] = {id: '1', label: 'ไม่ใช้งาน', class: 'danger', icon: 'fa-solid fa-circle-exclamation fa-fw'};
        }else if(dataSet === "import-status"){
            dataList[0] = {id: '0', label: 'นำเข้าไฟล์', class: 'primary', icon: 'fa-solid fa-cloud-arrow-up fa-fw' }
            dataList[1] = {id: '1', label: 'ใช้งานข้อมูล', class: 'success', icon: 'fa-solid fa-cloud-check fa-fw' };  
            dataList[2] = {id: '2', label: 'ข้อมูลผิดพลาด', class: 'danger', icon: 'fa-solid fa-cloud-exclamation fa-fw'};                                
        }else if(dataSet === "api-fetch-status"){
            dataList[0] = {id: '0', label: 'เชื่อมโยงข้อมูล', class: 'primary', icon: 'fa-solid fa-link fa-fw' }
            dataList[1] = {id: '1', label: 'ใช้งานข้อมูล', class: 'success', icon: 'fa-solid fa-badge-check fa-fw' };  
            dataList[2] = {id: '2', label: 'ข้อมูลผิดพลาด', class: 'danger', icon: 'fa-solid fa-star-exclamation fa-fw'};                                
        }  

        if(dataList.length){
            jsxDropdownButton = dataList.map((item,i) => {
                if(item.id === value){ title = item.label; dropdownClass = item.class; icon = item.icon; return null;}
                else{
                    return (<Dropdown.Item key={i} as={Button} size={size} variant="light" onClick={(e) => this.callback(item.id)}><i className={item.icon}></i> {item.label}</Dropdown.Item>)
                }
             });
        }      

        return(                      
            <Dropdown as={ButtonGroup} size={size} style={{width: '100%'}}>
                <Dropdown.Toggle variant={dropdownClass} size={size} style={{borderRadius: '0.625rem'}}><i className={icon}></i> {title}</Dropdown.Toggle>
                <Dropdown.Menu size={size}>
                    {jsxDropdownButton}
                </Dropdown.Menu>              
            </Dropdown>
        )
    }
}

export default ButtonDropdownComponent;