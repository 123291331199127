import React, {Component} from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Link} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';

import {AppMenu} from '../../config/AppConfig';

import * as StandardTask from '../utilities/StandardTask';

class SideBar extends Component {

   state = {
      active: '',
      activeSubmenu: ''
   }

   handleMenuActive = (title) => {
      if( this.state.active === title ){this.setState(prev => {return {active: ''}});}
      else{this.setState(prev => {return {active: title}});}     
   }
   
   handleSubMenuActive = (title) => {
      if( this.state.activeSubmenu === title ){this.setState(prev => {return {activeSubmenu: ''}});}
      else{this.setState(prev => {return {activeSubmenu: title}});}         
   }

   render(){ 

      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length-1];

      let MenuList = AppMenu;

      let userProfile = StandardTask.getUserProfile();

      /** 
      if(userProfile.department === '2'){
         MenuList = [
            {
                title: 'ติดตามการขนย้ายสินค้า',
                classChange: 'mm-show',
                iconStyle: <i className="fa-sharp fa-regular fa-truck fa-fw"></i>,
                to: '/Monitoring'
            }
         ];
      }
      */

      return (
         <div className="deznav" >
            <PerfectScrollbar className="deznav-scroll">          
               <ul className="metismenu" id="menu">
               {MenuList.map((data, index) => {
                  let menuClass = data.classChange;
                  if(menuClass === 'menu-title'){
                     return(<li className={menuClass} key={index}>{data.title}</li>)
                  }else{
                     return(
                        <li className={this.state.active === data.title ? "mm-active" : ""} key={index}>
                           {data.content && data.content.length > 0 ? 
                           <>
                              <Link to={"#"} className="has-arrow" onClick={(e) => this.handleMenuActive(data.title)}>
                                 {data.iconStyle}
                                 <span className="nav-text">{data.title}</span>
                              </Link>
                              <Collapse in={this.state.active === data.title ? true : false} >
                                 <ul className={menuClass === 'mm-collapse'? "mm-show" : "" }>
                                    {data.content && data.content.map((data,index) => {
                                       return (
                                          <li key={index} className={this.state.activeSubmenu === data.title ? "mm-active" : ""}>
                                             {data.content && data.content.length > 0 ? 
                                             <>
                                                <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''} onClick={(e) => this.handleSubMenuActive(data.title)}>
                                                   {data.title}
                                                </Link>
                                                <Collapse in={this.state.activeSubmenu === data.title ? true : false}>
                                                   <ul className={menuClass === 'mm-collapse' ? "mm-show" : ""}>
                                                      {data.content && data.content.map((data,index) => {
                                                         return (
                                                            <>
                                                               <li key={index}>
                                                                  <Link className={path === data.to ? "mm-active" : "" } to={data.to}>{data.title}</Link>
                                                               </li>
                                                            </>
                                                         )
                                                      })}
                                                   </ul>
                                                </Collapse>
                                             </>
                                             :
                                             <Link to={data.to}>{data.title}</Link>
                                             }
                                          </li>
                                       )
                                    })}
                                 </ul>
                              </Collapse>
                           </>
                           :
                           <Link to={data.to} target={data.target}>
                              {data.iconStyle}
                              <span className="nav-text">{data.title}</span>
                           </Link>
                           }
                        </li>
                     )
                  }
               })}
               </ul>                  
            </PerfectScrollbar>
         </div>
      )
   }
}

export default SideBar;