import React , {Component} from "react";

import Form from 'react-bootstrap/Form';
import * as StandardTask from '../utilities/StandardTask';

class SelectListComponent extends Component{

    callback = (e) => { if (this.props.CallBack instanceof Function) { this.props.CallBack(e);} }

    render(){

        let id = this.props.id;
        let name = this.props.name;
        let value = this.props.value;
        let dataSet = this.props.dataSet;
        let objectData = this.props.objectData;
        let dataFilter = this.props.dataFilter;
        let className = this.props.className;
        let disabled = this.props.disabled;

        // let defaultVal = false;
        if(className === undefined){ className = 'form-control';}   
        else{
            className += " form-control";
        }
        if(disabled === undefined){ disabled = false;}
        if(dataFilter === undefined){ dataFilter = '';}        
        
        let dataList = {};

        if(dataSet === "status"){ 
            dataList[0] = {id: '0', value: 'ใช้งาน'}
            dataList[1] = {id: '1', value: 'ไม่ใช้งาน'};
        }else if(dataSet === "theme"){ 
                dataList[0] = {id: '0', value: 'Light Mode'}
                dataList[1] = {id: '1', value: 'Dark Mode'};    
        }else if(dataSet === "font"){ 
                dataList[0] = {id: '0', value: 'ขนาดเล็ก'}
                dataList[1] = {id: '1', value: 'ขนาดกลาง'};   
                dataList[2] = {id: '2', value: 'ขนาดใหญ่'};                                      
        }else if(dataSet === "year"){
            let currentYear = new Date().getFullYear() - 5;
            for (let i = 0; i < 7 ; i++, currentYear++){ dataList[i] = {id: currentYear, value: (currentYear + 543)};}
        }else if(dataSet === "import-status"){
            dataList[0] = {id: '0', value: 'นำเข้าไฟล์'}
            dataList[1] = {id: '1', value: 'ใช้งานข้อมูล'}            
            dataList[2] = {id: '2', value: 'ข้อมูลผิดพลาด'}; 
        }else if(dataSet === "api-fetch-status"){
            dataList[0] = {id: '0', value: 'เชื่อมโยงข้อมูล'}
            dataList[1] = {id: '1', value: 'ใช้งานข้อมูล'}            
            dataList[2] = {id: '2', value: 'ข้อมูลผิดพลาด'};                    
        }else if(dataSet === "object"){     
            if(StandardTask.isObject(dataFilter)){
                let data = dataFilter.data;
                let filter = dataFilter.filter;
                let option = dataFilter.option;

                if(data === '' || data === undefined){ data = '';}
                if(option === '' || option === undefined){ option = '';}

                if( data === 'api'){
                    // console.log(filter, option);
                    // console.log("objectData", objectData);
                    const filterData = Object.keys(objectData).reduce((result, key) => {                                                              
                        if(objectData[key].dataType === filter){  
                            // console.log(result, key);
                            if(objectData[key].id === '2' && option === '1'){} /** HRMS - Profile โรงเรียน - remove รหัสบัตรประชาชน*/
                            else if(objectData[key].id === '2' && option === '2'){} /** HRMS - Person โรงเรียน - remove รหัสบัตรประชาชน*/
                            else{ result[key] = objectData[key]; }                                                    
                        }
                        return result;
                    }, {});
                    Object.keys(filterData).forEach((item) => { dataList[item] = {id: filterData[item].id, value: filterData[item].value} });
                    // if( data === 'api' && filter === '2' && option !== ''){ defaultVal = true;}
                }                
            }else{ Object.keys(objectData).forEach((item) => { dataList[item] = {id: objectData[item].id, value: objectData[item].value} });}
        }

        let size = StandardTask.getElementsSize();

        let jsxSelectList = [];
        for (let key in dataList ){ 
            jsxSelectList.push(<option value={dataList[key].id} key={key}>{dataList[key].value}</option>); 
            // if(defaultVal === true){ defaultVal = false; value = dataList[key].id; }
        }

        return (
            <Form.Control as="select" id={id} name={name} value={value} className={className} size={size} disabled={disabled} onChange={(e) => this.callback(e)}>
                <option value=''>กรุณาระบุ</option>
                {jsxSelectList}
            </Form.Control>
        )
    }
}

export default SelectListComponent;