import React, {Component} from "react";
import { Link } from "react-router-dom";


import profile from "../../images/profile/profile.png";
import { Dropdown } from "react-bootstrap";

import * as StandardTask from '../utilities/StandardTask';

class Header extends Component {

      render(){

         let userProfile = StandardTask.getUserProfile(); 

         return (
            <div className="header">
               <div className="header-content">
                  <nav className="navbar navbar-expand">
                     <div className="collapse navbar-collapse justify-content-between">
                        
                        <div className="header-left">
					               <div className="dashboard_bar"></div>
					         </div>

                        <ul className="navbar-nav header-right">    
						         <Dropdown as="li" className="nav-item header-profile ">
							         <Dropdown.Toggle as="a" to="#" variant="" className="nav-link i-false c-pointer">
								         <div className="header-info">
									         <span className="text-black"><strong>{userProfile.fullname}</strong></span>
								            <p className="fs-12 mb-0">{userProfile.department === '1' ? 'กรมการค้าภายใน' : 'กรมการขนส่งทางบก'}</p>
								         </div>
								         <img src={profile} width={20} alt="" className="image-shadow" style={{color: 'white'}}/>
							         </Dropdown.Toggle>
                              
                              <Dropdown.Menu align="end" className="mt-2">
                                 <Link to="/ChangePassword" className="dropdown-item ai-icon">
                                    <i className="fas fa-key fa-fw"></i>
                                    <span className="ms-2">เปลี่ยนรหัสผ่าน</span>
                                 </Link>
                                 <Link to="/" className="dropdown-item ai-icon">
                                    <i className="fas fa-sign-out-alt fa-fw"></i>
                                    <span className="ms-2">ออกจากระบบ</span>
                                 </Link>                              							 
                              </Dropdown.Menu>
                              
						         </Dropdown>                                                
                        </ul>

                     </div>
                  </nav>
               </div>
            </div>
         )
      }
}

export default Header;