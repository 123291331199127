import React, {Component} from "react";

import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';

import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import {ToastContainer, toast} from 'react-toastify';

import AlertComponent from './components/AlertComponent';

import ButtonComponent from './components/ButtonComponent';
import ButtonDropdownComponent from './components/ButtonDropdownComponent';

import ModalComponent from './components/ModalComponent';

import PaginationComponent from './components/PaginationComponent';

import SelectListComponent from './components/SelectListComponent';

import * as StandardTask from './utilities/StandardTask';

class UserAccount extends Component {

    REDIRECT_URL = process.env.REACT_APP_URL_ROOT;

    API_DEFAULT_END_POINT = process.env.REACT_APP_API_END_POINT;
    API_END_POINT = this.API_DEFAULT_END_POINT + "masterbase/monitor-user-account/";

    itemArrayDelete = [];

    state = {
        Control: {
            Title: 'ผู้ใช้งานระบบ',
            Icon: 'fa-solid fa-user-pilot-tie fa-fw',
            Init: 'Init',
            Mode: 'View',
            Page: '1',
            Modal: {Action: 'View', Toggle: false},
            Toast: {Delay: 800}
        },
        DataTable : {
            data: [],
            info: {}
        },
        Search: {
            idUserDepartment: {
                id: 'idUserDepartment',
                label: 'หน่วยงาน',
                value: ''
            },                       
            fullname: {
                id: 'fullname',
                label: 'ชื่อ-สกุล',
                value: ''
            },
            email: {
                id: 'email',
                label: 'อีเมล',
                value: ''
            },              
            username: {
                id: 'username',
                label: 'บัญชีผู้ใช้งาน',
                value: ''
            },            
            status: {                
                id: 'status',
                label: 'สถานะ', 
                value: ''
            }
        },
        Input: {
            id : {
                id: 'id',
                label: 'รหัสผู้ใช้งาน',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: false
                },
                error: {status: false, msg: ''}
            },            
            idUserDepartment: {
                id: 'idUserDepartment',
                label: 'หน่วยงาน',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 1,
                    maxLength: 1,
                },
                error: {status: false, msg: ''}
            },       
            fullname: {
                id: 'fullname',
                label: 'ชื่อ-สกุล',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 5,
                    maxLength: 250,
                },
                error: {status: false, msg: ''}
            },    
            email: {
                id: 'email',
                label: 'อีเมล',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: '5',
                    maxLength: '250',
                    pattern: 'email'
                },
                error: {status: false, msg: ''}
            },                     
            username: {
                id: 'username',
                label: 'บัญชีผู้ใช้งาน',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 3,
                    maxLength: 50,
                },
                error: {status: false, msg: ''}
            },      
            password: {
                id: 'password',
                label: 'รหัสผ่าน',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 8,
                    maxLength: 50,
                },
                error: {status: false, msg: ''}
            },                                                      
            status : {
                id: 'status',
                label: 'สถานะ',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 1,
                    maxLength: 1,
                },
                error: {status: false, msg: ''}
            },                                  
        },
        MasterDataUserDepartment: {},       
        Delete: [],
        FormStatus: false ,
        Debug: false   
    };

    StagingControl = (action, key, value) => { this.setState(prev => {return {[key]: value} }, () => { if(this.state.Debug === true){ console.log( action + " : ", this.state );} });}
    
    FlowControl = (action) => { if((action === 'Delete' && this.state.Delete.length > 0) || action !== 'Delete'){ const ControlElements = StandardTask.FlowController(action, {...this.state.Control}); this.StagingControl('FlowControl - ' + action , 'Control', ControlElements); } }

    ModalControl = (action, bl) => {
        let ControlElements = StandardTask.ModalController(action, {...this.state.Control});
        this.setState(prev => {return {Control: ControlElements} }, () => {
            if(this.state.Debug === true){ console.log("ModalClose - " + action + " : ", this.state.Control); }
            if(bl === true){ if(action === 'Delete'){this.Delete();} }        
        });        
    }    
     
    DeleteControl = (e) => {
        e.target.checked === true ? this.itemArrayDelete.push(e.target.value) : this.itemArrayDelete.pop(e.target.value);
        this.StagingControl('Delete - Item', 'Delete', this.itemArrayDelete);        
    }

    EditControl = async (id) => {
        await axios.get( this.API_END_POINT + id)
        .then((res) => {
            if(res.data.status){
                let InputElements = {...this.state.Input};
                Object.keys(res.data.result[0]).forEach((item, i) => { InputElements[item].value = res.data.result[0][item]; });
                this.setState(prev => {return {Input: InputElements} }, () => { this.FlowControl('Edit'); });
            }
        }).catch((err) => { if(this.state.Debug === true){ console.log("EditControl.Error : ", err)} });
    }    

    PagingControl = (page) => {
        let ControlElements = StandardTask.PagingController({...this.state.Control}, page);
        this.setState(prev => {return {Control: ControlElements} }, () => { if(this.state.Debug === true){ console.log("Pagination : ", this.state.Control);} this.DataTable(); });          
    }    

    SearchChange = (e) => { const SearchElements = StandardTask.SearchController({...this.state.Search}, e.target.name, e.target.value); this.StagingControl('SearchChange' , 'Search', SearchElements); }

    InputChange = (e) => {
        let FormStatusChecked = false;
        const result = StandardTask.InputController({...this.state.Input}, e.target.name, e.target.value, true);
        this.setState(prevState => {return {Input: result.InputElements, FormStatus: result.FormStatus}}, () => { 
            if(this.state.Debug === true){ console.log("InputChange : ", this.state.Input); }

            if((this.state.Control.Mode === 'Entry' || this.state.Control.Mode === 'Edit') && e.target.name === 'idUserDepartment'){
                let InputElements = {...this.state.Input};
                this.setState(prevState => {return {Input: InputElements}}, () => {
                    if(FormStatusChecked){
                        let idUserDepartment = this.state.Input.idUserDepartment.value;
                        let FormStatus = this.state.FormStatus;
                        if( FormStatus === true && idUserDepartment !== '0'){ FormStatus = true; } 
                        this.setState(prevState => {return {FormStatus: FormStatus}}, () => { if(this.state.Debug === true){ console.log("FormStatusChange : ", this.state.FormStatus); }});
                    }
                }); 
            }
        });         
    } 

    FormDiscard = () => {
        let InputElements = StandardTask.ResetInput({...this.state.Input});
        this.setState(prev => {return {Input: InputElements} }, () => { if(this.state.Debug === true){ console.log("ResetInput : ", this.state.Input);} this.FlowControl('FormDiscard'); });       
    } 
    
    Search = () => {
        let ControlElements = StandardTask.SearchPrompt({...this.state.Control});
        this.setState(prev => {return {Control: ControlElements} }, () => { if(this.state.Debug === true){ console.log("Search : Control - ", this.state.Control, " Filter : ", this.state.Search);} this.DataTable(); });         
    }

    DataTable = async () => {
        const params = StandardTask.SearchParams({...this.state.Control}, {...this.state.Search});
        await axios.get( this.API_END_POINT + "pagination", params)
        .then((res)=>{ this.StagingControl('DataTable', 'DataTable', {data: res.data.result, info: res.data.info}); })
        .catch((err)=>{ if(this.state.Debug === true){ console.log("DataTable.Error : ", err)} });
    }
    
    InputToDB = (mode) => {   
        let method, transaction;     
        mode === 'Entry' ? method = 'POST' : method = 'PUT';
        const inputs = StandardTask.InputForm({...this.state.Input});
        
        axios.request({method: method, url: this.API_END_POINT, data: inputs})
        .then((res) => {            
            if(this.state.Debug === true){ console.log("Debug Transaction : ", res); } 
            if( res.data === false ){ StandardTask.Notification(toast, mode, 'error', this.state.Control.Title); }
            else{
                if( res.data.status === false){ transaction = 0; StandardTask.Notification(toast, mode, 'warning', this.state.Control.Title); }
                else{ transaction = 1; StandardTask.Notification(toast, mode, 'success', this.state.Control.Title); }
                if(transaction === 1){
                    let ControlElements = StandardTask.CommitController({...this.state.Control});
                    this.setState(prev => {return {Control: ControlElements}}, () => { this.FormDiscard(); this.DataTable();});                
                }  
            }       
        }).catch((err) => { if(this.state.Debug === true){console.log("InputToDB.Error : ", err);} });
    }

    UpdateStatus = async (id, value) => {
        await axios.put(this.API_END_POINT + "on-demand", {data: {id: id, field: 'status', value: value}})
        .then((res) => { 
            if(this.state.Debug === true){ console.log("Debug Transaction : ", res); } 
            if(res.data.status){ StandardTask.Notification(toast, 'Status', 'success', this.state.Control.Title); this.DataTable(); }            
        }).catch((err) => {if(this.state.Debug === true){ console.log("UpdateStatus.Error : ", err); }});
    }     

    Delete = async () => {
        if(this.state.Delete.length > 0){
            await axios.delete( this.API_END_POINT, {params: {id: this.state.Delete}})
            .then((res) => {
                if(this.state.Debug === true){ console.log("Debug Transaction : ", res); }
                if(res.data.status){
                    StandardTask.Notification(toast, 'Delete', 'success', this.state.Control.Title);
                    this.setState(prev => {return {Delete: []}}, () => { this.itemArrayDelete = []; this.DataTable(); });
                }
            }).catch((err) => { if(this.state.Debug === true){ console.log("Delete.Error : ", err)} });
        }
    }    

    LoadMasterData = async () => {
        let UserDepartmentElements = {};

        await axios.get(this.API_DEFAULT_END_POINT + "dataset-master/list-user-department/")
        .then((res)=>{
            res.data.result.forEach((item, index) => { UserDepartmentElements[index] = {id: item.id, value: item.name} });
            this.setState(prev => {return {MasterDataUserDepartment: UserDepartmentElements} }, () => { if(this.state.Debug === true){ console.log("LoadUserDepartment : ", this.state.MasterDataUserDepartment);} });              
        }).catch((err)=>{ if(this.state.Debug === true){ console.log("LoadUserDepartment.Error : ", err) }});       
    }

    RandomPassword = () => { StandardTask.TriggerInputChange('text', 'password', StandardTask.RandomPassword()); }

    componentDidMount = () => { 
        this.DataTable(); this.LoadMasterData();     
    }

    render(){

        let Control = this.state.Control;
        let Search = this.state.Search;
        let Input = this.state.Input;

        const DataTable = this.state.DataTable; 
        let rowId = ((DataTable.info.currentPage * DataTable.info.pageSize) - DataTable.info.pageSize) + 1;   

        let recordCount = 0; 
        let deleteCount = 0;

        let size = StandardTask.getElementsSize();

        let jsxDataTable = DataTable.data.map((item, i) => {
            recordCount++;

            if(item.status === '1'){deleteCount++;} 

            return(
                <tr key={i}>
                    <td className="td-center">{rowId++}</td>
                    <td>{item.departmentName}</td>
                    <td>{item.fullname}</td>
                    <td>{item.email}</td>
                    <td>{item.username}</td>
                    <td className="td-center">
                        { parseInt(StandardTask.getUserProfile().id) === 1 ? 
                        <ButtonDropdownComponent value={item.status} dataSet="status" size="sm" CallBack={(value) => this.UpdateStatus(item.id, value)}></ButtonDropdownComponent>    
                        : item.status === '0' ? <><i className="fa-solid fa-shield-check fa-fw"></i> ใช้งาน</> : <><i className="fa-solid fa-ban fa-fw"></i> ไม่ใช้งาน</>}
                    </td>
                    <td className="td-center">
                        { parseInt(StandardTask.getUserProfile().id) === parseInt(item.id) ? 
                            <ButtonComponent action="Edit" CallBack={(e) => this.EditControl(item.id)}></ButtonComponent>
                        : parseInt(StandardTask.getUserProfile().id) === 1 ? 
                            <ButtonComponent action="Edit" CallBack={(e) => this.EditControl(item.id)}></ButtonComponent>
                        : '' }
                    </td>
                    <td className="td-center">
                        {item.status === '1' ?
                            <Form.Check type="checkbox" key={item.id} value={item.id} size={size} onClick={(e) => this.DeleteControl(e)}></Form.Check>
                        :''}
                    </td>
                </tr>
            )
        });  

        return (
            <>

                <ModalComponent modal={Control.Modal} CallBack={(action, bl) => this.ModalControl(action, bl)}></ModalComponent>            
                
                <ToastContainer autoClose={Control.Toast.Delay} closeOnClick className="toast-border-radius" />                   

                <div className="form-head mb-4">
                    <h2 className="text-black font-w400 mb-0">
                        <i className={Control.Icon}></i> {Control.Title} 

                        {Control.Mode === 'View'?
                            <ButtonComponent action="New" CallBack={(e) => this.FlowControl('New')}></ButtonComponent>
                        :''}                        
                    </h2>
                </div>             

                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>

                            {Control.Mode === 'View' ?                                
                                <>
                                    <Row>
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>หน่วยงาน</label>
                                                <SelectListComponent 
                                                    id={Search.idUserDepartment.id} 
                                                    name={Search.idUserDepartment.id} 
                                                    value={Search.idUserDepartment.value} 
                                                    dataSet="object"
                                                    objectData={this.state.MasterDataUserDepartment}                                                         
                                                    CallBack={(e) => this.SearchChange(e)}
                                                ></SelectListComponent>                                         
                                        </Col>
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>ชื่อ-สกุล</label>
                                                <Form.Control
                                                    type="text" 
                                                    id={Search.fullname.id} 
                                                    name={Search.fullname.id} 
                                                    defaultValue={Search.fullname.value}
                                                    placeholder={Search.fullname.label}
                                                    onChange={(e) => this.SearchChange(e)}  
                                                    size={size}                                        
                                                ></Form.Control>                                           
                                        </Col>  
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>อีเมล</label>
                                                <Form.Control
                                                    type="text" 
                                                    id={Search.email.id} 
                                                    name={Search.email.id} 
                                                    defaultValue={Search.email.value}
                                                    placeholder={Search.email.label}
                                                    onChange={(e) => this.SearchChange(e)}  
                                                    size={size}                                          
                                                ></Form.Control>                                             
                                        </Col>                                         
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>บัญชีผู้ใช้งาน</label>
                                                <Form.Control
                                                    type="text" 
                                                    id={Search.username.id} 
                                                    name={Search.username.id} 
                                                    defaultValue={Search.username.value}
                                                    placeholder={Search.username.label}
                                                    onChange={(e) => this.SearchChange(e)}  
                                                    size={size}                                          
                                                ></Form.Control>                                             
                                        </Col>   
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>สถานะ</label>
                                                <SelectListComponent 
                                                    id={Search.status.id} 
                                                    name={Search.status.id} 
                                                    value={Search.status.value} 
                                                    dataSet="status" 
                                                    CallBack={(e) => this.SearchChange(e)}
                                                ></SelectListComponent>                                             
                                        </Col>                                          
                                        <Col className="form-group mb-3" lg={2}  style={{ alignSelf: 'end' }}>
                                            <ButtonComponent action="Search" CallBack={(e) => this.Search()}></ButtonComponent>
                                        </Col>                                                                     
                                    </Row>                              

                                    {DataTable.info.totalRows === 0 ? 
                                        <AlertComponent type="NoResult"></AlertComponent>
                                    :
                                    <>
                                        <Table responsive hover className="table mb-0 table-striped respon-table-data">
                                            <thead>
                                                <tr>
                                                    <th className="th-row th-center">#</th>
                                                    <th style={{minWidth: '150px'}}>หน่วยงาน</th>
                                                    <th style={{minWidth: '200px'}}>ชื่อ-สกุล</th>
                                                    <th style={{minWidth: '200px'}}>อีเมล</th>
                                                    <th style={{minWidth: '150px'}}>บัญชีผู้ใช้งาน</th>
                                                    <th className="th-center th-default-status">สถานะ</th>
                                                    <th className="th-edit"></th>
                                                    <th className="th-delete">
                                                        {(recordCount !== 0 && deleteCount > 0 ) ? <ButtonComponent action="Delete" CallBack={(e) => this.FlowControl('Delete')}></ButtonComponent> : ''}                                               
                                                    </th>                                            
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jsxDataTable}
                                            </tbody>                                        
                                        </Table>

                                        <PaginationComponent info={DataTable.info} CallBack={(page) => this.PagingControl(page)}></PaginationComponent>
                                    </>
                                    }
                                </>
                            :''}

                            {Control.Mode === 'Entry' || Control.Mode === 'Edit' ? 
                                <Row>
                                    <Col lg={8}>
                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <label htmlFor={Input.idUserDepartment.id}>* {Input.idUserDepartment.label}</label>
                                                <SelectListComponent 
                                                    id={Input.idUserDepartment.id} 
                                                    name={Input.idUserDepartment.id} 
                                                    value={Input.idUserDepartment.value} 
                                                    dataSet="object"
                                                    objectData={this.state.MasterDataUserDepartment}  
                                                    className={StandardTask.GetInputClass(Input, 'idUserDepartment')}                                                                                                                   
                                                    CallBack={(e) => this.InputChange(e)}
                                                ></SelectListComponent> 
                                                <Form.Control.Feedback type="is-valid" className="mt-3">{StandardTask.GetErrorMessage(Input, 'idUserDepartment')}</Form.Control.Feedback>                                               
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={8} className="mb-3">
                                                <label htmlFor={Input.fullname.id}>* {Input.fullname.label}</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id={Input.fullname.id} 
                                                    name={Input.fullname.id} 
                                                    defaultValue={Input.fullname.value}
                                                    placeholder={Input.fullname.label}
                                                    className={StandardTask.GetInputClass(Input, 'fullname')}
                                                    onChange={(e) => this.InputChange(e)}
                                                    size={size}  
                                                ></Form.Control> 
                                                 <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'fullname')}</Form.Control.Feedback>                                                   
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <label htmlFor={Input.email.id}>* {Input.email.label}</label>
                                                <Form.Control 
                                                    type="email" 
                                                    id={Input.email.id} 
                                                    name={Input.email.id} 
                                                    defaultValue={Input.email.value}
                                                    placeholder={Input.email.label}
                                                    className={StandardTask.GetInputClass(Input, 'email')}
                                                    onChange={(e) => this.InputChange(e)}
                                                    size={size}  
                                                ></Form.Control> 
                                                <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'email')}</Form.Control.Feedback>                                                    
                                            </Col>                                        
                                        </Row>

                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <label htmlFor={Input.username.id}>* {Input.username.label}</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id={Input.username.id} 
                                                    name={Input.username.id} 
                                                    defaultValue={Input.username.value}
                                                    placeholder={Input.username.label}
                                                    className={StandardTask.GetInputClass(Input, 'username')}
                                                    disabled={Control.Mode === 'Edit' ? true : false }
                                                    onChange={(e) => this.InputChange(e)}
                                                    size={size}  
                                                ></Form.Control>                                                 
                                                <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'username')}</Form.Control.Feedback>                                                     
                                            </Col>
                                            <Col lg={4} className="mb-3">
                                                <label htmlFor={Input.password.id}>* {Input.password.label}</label>
                                                <Form.Control 
                                                    type="text" 
                                                    id={Input.password.id} 
                                                    name={Input.password.id} 
                                                    value={Input.password.value}
                                                    placeholder={Input.password.label}
                                                    className={StandardTask.GetInputClass(Input, 'password')}
                                                    onChange={(e) => this.InputChange(e)}
                                                    size={size}  
                                                ></Form.Control>  
                                                <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'password')}</Form.Control.Feedback>                                                                                                      
                                            </Col>  
                                            <Col lg={4} className="mb-3" style={{ alignSelf: 'end' }}>
                                                <ButtonComponent action="RandomPassword" CallBack={(e) => this.RandomPassword()}></ButtonComponent> 
                                            </Col>                                          
                                        </Row>                                                                                                                                                            

                                        <Row>
                                            <Col lg={4} className="mb-3">
                                                <label htmlFor={Input.status.id}>* {Input.status.label}</label>
                                                <SelectListComponent 
                                                    id={Input.status.id} 
                                                    name={Input.status.id} 
                                                    value={Input.status.value} 
                                                    dataSet="status" 
                                                    className={StandardTask.GetInputClass(Input, 'status')}
                                                    CallBack={(e) => this.InputChange(e)}
                                                ></SelectListComponent>
                                                <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'status')}</Form.Control.Feedback>                                                 
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={8} className="mb-3">  
                                                <ButtonComponent action="Save" disabled={!this.state.FormStatus} CallBack={(e) => this.InputToDB(this.state.Control.Mode)}></ButtonComponent> 
                                                <ButtonComponent action="Discard" CallBack={(e) => this.FormDiscard()}></ButtonComponent>                                              
                                            </Col>
                                        </Row>                                  

                                    </Col>
                                    <Col lg={4}></Col>
                                </Row> 
                            :''} 

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                   

            </>
        );
    }
}
export default UserAccount;