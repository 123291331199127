import React , {Component} from "react";
import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form  from 'react-bootstrap/Form';

import { FormGroup } from "react-bootstrap";

import Card from 'react-bootstrap/Card';

import AlertComponent from './components/AlertComponent';

import ButtonComponent from './components/ButtonComponent';

import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

import PaginationComponent from './components/PaginationComponent';

import SelectListComponent from './components/SelectListComponent';

import Loading from './components/Loading';

import DatePickerThComponent from './components/DatePickerThComponent';

import * as StandardTask from './utilities/StandardTask.js';

import withRouter from './withRouter';

import {StyledDataGridConfig} from '../config/AppConfig';
  
class MonitoringTracking extends Component{

    API_DEFAULT_END_POINT = process.env.REACT_APP_API_END_POINT;

    API_END_POINT = process.env.REACT_APP_API_END_POINT + "monitoring-tracking/";

    state = {
        Control: {
            Title: 'ตรวจสอบการเรียกข้อมูล',
            Icon: 'fa-solid fa-camera-cctv fa-fw',
            Init: 'Init',
            Mode: 'View',
            Page: '1',
            Modal: {Action: 'View', Toggle: false},
            Toast: {Delay: 800}
        },
        DataTable : {
            columnGroupingModel: [],
            columns: [],
            rows: [],
            info: {}
        },
        Search: {        
            userId: {
                id: 'userId',
                value: StandardTask.getUserProfile().id
            },    
            userDepartment: {
                id: 'userDepartment',
                value: StandardTask.getUserProfile().department
            },           
            productItem: {
                id: 'productItem',
                label: 'ประเภทสินค้า',
                value: ''
            },
            keywordSearch: {
                id: 'keywordSearch',
                label: 'คำค้น',
                value: ''
            },              
            startDate: {
                id: 'startDate',
                label: 'สืบค้นตั้งแต่วันทีี่',
                value: ''
            },            
            endDate: {                
                id: 'endDate',
                label: 'ถึงวันที่', 
                value: ''
            }
        },
        MasterDataProductItem: {},           
        Loading: false,
        Debug: false      
    } 
    
    StagingControl = (action, key, value) => { this.setState(prev => {return {[key]: value} }, () => { if(this.state.Debug === true){ console.log( action + " : ", this.state );} });}
    
    FlowControl = (action) => { const ControlElements = StandardTask.FlowController(action, {...this.state.Control}); this.StagingControl('FlowControl - ' + action , 'Control', ControlElements); }
   
    SearchChange = (e) => { const SearchElements = StandardTask.SearchController({...this.state.Search}, e.target.name, e.target.value); this.StagingControl('SearchChange' , 'Search', SearchElements); }

    Search = () => {
        let ControlElements = StandardTask.SearchPrompt({...this.state.Control});
        this.setState(prev => {return {Control: ControlElements} }, () => { if(this.state.Debug === true){ console.log("Search : Control - ", this.state.Control, " Filter : ", this.state.Search);} this.DataTable(); });         
    }

    PagingControl = (page) => {
        let ControlElements = StandardTask.PagingController({...this.state.Control}, page);
        this.setState(prev => {return {Control: ControlElements}}, () => { if(this.state.Debug === true){ console.log("Pagination : ", this.state.Control);} this.DataTable(); });          
    }    
    
    DataTable = async () => {    
        let columns = [];
        let rows = [];  
        let record = {};
        let rowId;
        
        // this.StagingControl('Loading', 'Loading', true);

        const params = StandardTask.SearchParams({...this.state.Control}, {...this.state.Search});
        await axios.get(this.API_END_POINT + "pagination", params)
        .then((res)=>{ 

            rowId = ((res.data.info.currentPage * res.data.info.pageSize) - res.data.info.pageSize) + 1;

            columns = [
                {field: 'id', headerName: '#', width: 75, headerAlign: 'center', align: 'center'},
                {field: 'queryDate', headerName: 'สืบค้นเมื่อ', width: 175, headerAlign: 'left', align: 'left'},                 
                {field: 'productName', headerName: 'สินค้า', width: 250, headerAlign: 'left', align: 'left'},                    
                {field: 'department', headerName: 'หน่วยงาน', width: 175, headerAlign: 'left', align: 'left'},
                {field: 'fullname', headerName: 'ชื่อ - นามสกุล', width: 200, headerAlign: 'left', align: 'left'}, 
                {field: 'username', headerName: 'บัญชีผู้ใช้', width: 175, headerAlign: 'left', align: 'left'}, 
                {field: 'keywords', headerName: 'คำค้น', flex: 1, headerAlign: 'left', align: 'left'}, 
                {field: 'startDate', headerName: 'เริ่มต้นขนย้าย', width: 125, headerAlign: 'left', align: 'left'},  
                {field: 'endDate', headerName: 'สิ้นสุดการขนย้าย', width: 125, headerAlign: 'left', align: 'left'},     
                {field: 'ipAddress', headerName: 'ไอพีแอดเดรส', width: 150, headerAlign: 'left', align: 'left'},
            ];

            res.data.result.forEach((item) => { 
                record = {
                    id: rowId,
                    queryDate: StandardTask.DateTime(item.queryDate, 1),
                    productName: item.productName,
                    department: item.department,
                    fullname: item.fullname,
                    username: item.username,
                    keywords: item.keywords,
                    startDate: item.startDate === '' ? 'ไม่ระบุ' : item.startDate,
                    endDate: item.endDate === '' ? 'ไม่ระบุ' : item.endDate,
                    ipAddress: item.ipAddress
                }
                rows.push(record);
                rowId++;  
            });          

            this.StagingControl('DataTable', 'DataTable', {columns: columns, rows: rows, info: res.data.info});
            // this.StagingControl('Loading', 'Loading', false);
        })
        .catch((err)=>{ if(this.state.Debug === true){ console.log("DataTable.Error : ", err)} });            
    }

    LoadData = async () => { this.LoadMasterData(); this.DataTable(); }

    LoadMasterData = async () => {
        let ProductItemElements = {};

        await axios.get(this.API_DEFAULT_END_POINT + "dataset-master/list-product-item/")
        .then((res)=>{
            res.data.result.forEach((item, index) => { ProductItemElements[index] = {id: item.id, value: item.name} });
            this.setState(prev => {return {MasterDataProductItem: ProductItemElements} }, () => { if(this.state.Debug === true){ console.log("LoadProductItem : ", this.state.MasterDataProductItem);} });              
        }).catch((err)=>{ if(this.state.Debug === true){ console.log("LoadProductItem.Error : ", err) }});       
    }

    DatePickerChange = (id, datePicked) => {
        const SearchElements = StandardTask.SearchController({...this.state.Search}, id, datePicked); this.StagingControl('DateChange' , 'DatePicker', SearchElements);        
    };

    componentDidMount = () => { this.LoadData(); }

    render(){

        let Control = this.state.Control; 

        let Search = this.state.Search;

        let DataInfo = this.state.DataInfo;

        let DataTable = this.state.DataTable; 

        let size = StandardTask.getElementsSize();

        const StyledDataGrid = styled(DataGrid)(StyledDataGridConfig);  
                  
        return(
            <>
                {this.state.Loading ? 
                    <Loading />
                :          
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={12}>
                                    <div className="form-head mb-4">
                                        <h2 className="text-black font-w600 mb-0">
                                            <i className={Control.Icon}></i> {Control.Title}                      
                                        </h2>
                                    </div>  
                                </Col>                            
                            </Row> 

                            <Row>
                                <Col className="form-group mb-3" lg={2}>
                                    <label>ประเภทสินค้า</label>
                                        <SelectListComponent 
                                            id={Search.productItem.id} 
                                            name={Search.productItem.id} 
                                            value={Search.productItem.value} 
                                            dataSet="object"
                                            objectData={this.state.MasterDataProductItem}                                                         
                                            CallBack={(e) => this.SearchChange(e)}
                                        ></SelectListComponent>                                      
                                </Col>
                                <Col className="form-group mb-3" lg={4}>
                                    <label>คำค้น</label>
                                        <Form.Control
                                            type="text" 
                                            id={Search.keywordSearch.id} 
                                            name={Search.keywordSearch.id} 
                                            defaultValue={Search.keywordSearch.value}
                                            placeholder={Search.keywordSearch.label}
                                            onChange={(e) => this.SearchChange(e)}  
                                            size={size}                                        
                                        ></Form.Control>                                           
                                </Col>  
                                <Col className="form-group mb-3" lg={2}>
                                    <label>สืบค้นตั้งแต่วันที่</label>
                                    <FormGroup>
                                        <DatePickerThComponent
                                            id="startDate"
                                            CallBack={this.DatePickerChange}                                             
                                        />       
                                    </FormGroup>                                 
                                </Col>                                         
                                <Col className="form-group mb-3" lg={2}>
                                    <label>ถึงวันที่</label>
                                    <FormGroup>
                                        <DatePickerThComponent
                                            id="endDate"
                                            CallBack={this.DatePickerChange}                                          
                                        />       
                                    </FormGroup>                                            
                                </Col>                                            
                                <Col className="form-group mb-3" lg={2}  style={{ alignSelf: 'end' }}>
                                    <ButtonComponent action="Search" CallBack={(e) => this.Search()}></ButtonComponent>
                                </Col>                                                                     
                            </Row>  

                            <Row>
                                <Col lg={12} className="mb-3">
                                    <>
                                        {DataTable.info.totalRows === 0 ? 
                                            <AlertComponent type="NoResult"></AlertComponent>
                                        : 
                                                                  
                                            <div style={{ width: '100%'}}>
                                                <Box sx={{ height: 700, width: '100%' }}>
                                                    <StyledDataGrid
                                                        experimentalFeatures={{ columnGrouping: true }}
                                                        columnGroupingModel={DataTable.columnGroupingModel}                                                      
                                                        columns={DataTable.columns}                                                      
                                                        rows={DataTable.rows}                                                         
                                                        disableRowSelectionOnClick
                                                        slots={{
                                                            toolbar: GridToolbar,                                                        
                                                            pagination: () => null,
                                                        }}  
                                                        density="compact"                                                        
                                                    />                                                    
                                                </Box>

                                                <PaginationComponent info={DataTable.info} CallBack={(page) => this.PagingControl(page)}></PaginationComponent> 
                                            </div>  
                                        }
                                    </>                                 
                                </Col>
                            </Row>                            
                        </Card.Body>
                    </Card>
                }
            </>
        )
    }
}

export default withRouter(MonitoringTracking);
