import React , {Component} from "react";

import Badge from 'react-bootstrap/Badge';

import * as StandardTask from '../utilities/StandardTask';
class BadgeComponent extends Component{

    callback = (e) => {
        if (this.props.CallBack instanceof Function) { this.props.CallBack(e); }
    }

    render(){

        let bg = this.props.bg;
        let value = this.props.value;
        let label = this.props.label;
        let text = this.props.text;

        let action = 0;
        let jsxStyle = {};

        if(text === '' || text === undefined){ text = 'light';}
        
        if (this.props.CallBack !== undefined) {
            jsxStyle = {cursor: 'pointer'};
            action = 1;
        }

        let size = StandardTask.getElementsSize();;

        return (
            <> 
                {action === 0 ? 
                    <><Badge bg={bg} text={text} size={size}>{value}</Badge> {label} </>
                :
                    <><Badge bg={bg} text={text} style={jsxStyle} size={size} onClick={(e) => this.callback(e)}>{value}</Badge> {label}</>
                }     
            </>
            )
    }
}
export default BadgeComponent;        