import React, {Component} from "react";
import {Link} from 'react-router-dom';

import logo from '../../images/logo-nav-head-02.png';
import logoText from '../../images/logo-sm-login-01.png';

class NavHeader extends Component {

      state = { toggle: false}

      NavMenuToggle = () => {
         setTimeout(()=>{	
            let mainwrapper = document.querySelector("#main-wrapper");
            if(mainwrapper.classList.contains('menu-toggle')){ mainwrapper.classList.remove("menu-toggle");}
            else{ mainwrapper.classList.add("menu-toggle");}
         },200);
      }

      setToggle = (toggle) => { this.setState(prev => {return {toggle: toggle}}, () => {}); }

      render(){
         return (
            <div className="nav-header">
               <Link to="/" className="brand-logo">
                  <img className="logo-abbr" src={logo} alt=""></img>
                  <img className="logo-compact" src={logoText} alt=""></img>
                  <img className="brand-title" src={logoText} alt=""></img>
               </Link>

               <div className="nav-control" onClick = {(e) => { this.setToggle(!this.state.toggle); this.NavMenuToggle();}}>
                  <div className={`hamburger ${this.state.toggle ? "is-active" : ""}`}>
                     <span className="line"></span>
                     <span className="line"></span>
                     <span className="line"></span>
                  </div>
               </div>
            </div>
         )
      }
}

export default NavHeader;