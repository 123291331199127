import React , {Component} from "react";
import axios from 'axios';

// import {Link} from 'react-router-dom';

import Form from 'react-bootstrap/Form';

import ButtonComponent from './components/ButtonComponent';

import * as StandardTask from './utilities/StandardTask';

import LoginBG from '../images/DIT-BG.png';
import LeftLoginSection from '../images/Left-Login-Section-01.png';

class Login extends Component {

    API_END_POINT = process.env.REACT_APP_API_CORS + "/login";
    REDIRECT_URL = process.env.REACT_APP_URL_ROOT + "Monitoring";

    state = {
        Input: {
            username : {
                id: 'username',
                label: 'บัญชีผู้ใช้งาน',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 3,
                    maxLength: 50,
                },
                error: {status: false, msg: ''}
            }, 
            password: {
                id: 'password',
                label: 'รหัสผ่าน',
                value: '',                
                validator: {                    
                    type: 'text',
                    required: true,
                    minLength: 8,
                    maxLength: 50,
                },
                error: {status: false, msg: ''}
            },                          
        },
        Error: { status: false, message: ''},
        Loading: true,
        FormStatus: false ,        
        Debug: false          
    }   
    
    StagingControl = (action, key, value) => { this.setState(prev => {return {[key]: value} }, () => { if(this.state.Debug === true){ console.log( action + " : ", this.state );} });}
    
    InputChange = (e) => {
        const result = StandardTask.InputController({...this.state.Input}, e.target.name, e.target.value, true);
        this.setState(prevState => {return {Input: result.InputElements, FormStatus: result.FormStatus}}, () => { if(this.state.Debug === true){ console.log("InputChange : ", this.state.Input); }});         
    } 

    Login = (mode) => {   
        let method;     
        // , transaction

        method = 'POST';
        const inputs = StandardTask.InputForm({...this.state.Input});
        
        this.setState(prev => {return {Error: {status: false, message: ''}} });

        axios.request({method: method, url: this.API_END_POINT, data: inputs, withCredentials: true })
        .then((res) => {     
            if(res.data.status){

                let  hours = 3;
                let expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + hours * 60 * 60 * 1000); // Convert hours to milliseconds
              
                let expires = `expires=${expirationDate.toUTCString()}`;

                const serializedObject = JSON.stringify(res.data.payload);

                document.cookie = "ditUserToken=" + res.data.token + "; expires=" + expires + "; path=/";
                document.cookie = "ditUserProfile=" + encodeURIComponent(serializedObject) + "; expires=" + expires + "; path=/";

                return window.location.href = this.REDIRECT_URL;
            }else{
                this.setState(prev => {return {Error: {status: true, message: "บัญชีผู้ใช้ไม่ถูกต้อง"}} });
            }
        }).catch((err) => { if(this.state.Debug === true){ console.log("InputToDB.Error : ", err)} });
    }   
    
    componentDidMount = async () => {
            const pastDate = new Date(0);
            document.cookie = `ditUserToken=; expires=${pastDate.toUTCString()}; path=/`;  
            document.cookie = `ditUserProfile=; expires=${pastDate.toUTCString()}; path=/`;                     
    }    

    render(){

        let Input = this.state.Input;

        return (
            <div className="login-main-page" style={{backgroundImage:"url("+ LoginBG+")"}}>
                <div className="login-wrapper">
                    <div className="login-aside-left" style={{backgroundImage:"url("+ LeftLoginSection +")"}}></div>
                    <div className="login-aside-right">
                        <div className="row m-0 justify-content-center h-100 align-items-center">
                            <div className="col-xl-7 col-xxl-7">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form-1">
                                                <div className="mb-4">
                                                    {/** 
                                                    <img src = {logo}></img>
                                                    <img src = {logoText}></img>
                                                    <img src = {logo} style={{maxWidth: '300px'}} alt="Login"></img>                                                       
                                                     */}
                                                    <font style={{color: '#e62f30', fontSize: '1.8rem', fontWeight: 'bold'}}>กรมการค้าภายใน</font>
                                                    <p style={{color: ' #ccc', fontSize: '0.8rem'}}>ระบบติดตามการขนย้ายสินค้าควบคุมทางอิเล็กทรอนิกส์</p>                                                                                                                                         
                                                </div>                                           

                                                <div className="form-group">
                                                    <label className="mb-2 " htmlFor={Input.username.id}>
                                                        <strong className="text-white">{Input.username.label}</strong>
                                                    </label>
                                                    <Form.Control 
                                                        type="text" 
                                                        id={Input.username.id} 
                                                        name={Input.username.id} 
                                                        value={Input.username.value}
                                                        placeholder={Input.username.label}
                                                        className={StandardTask.GetInputClass(Input, 'username')}
                                                        onChange={(e) => this.InputChange(e)}
                                                    ></Form.Control>   
                                                    <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'username')}</Form.Control.Feedback>                                                   
                                                </div>  

                                                <div className="form-group">
                                                    <label className="mb-2 ">
                                                        <strong className="text-white">{Input.password.label}</strong>
                                                    </label>
                                                    <Form.Control 
                                                        type="password" 
                                                        id={Input.password.id} 
                                                        name={Input.password.id} 
                                                        value={Input.password.value}
                                                        placeholder={Input.password.label}
                                                        className={StandardTask.GetInputClass(Input, 'password')}
                                                        onChange={(e) => this.InputChange(e)}
                                                    ></Form.Control>   
                                                    <Form.Control.Feedback type="is-valid">{StandardTask.GetErrorMessage(Input, 'password')}</Form.Control.Feedback> 
                                                </div>        
                                                <div className="text-center">
                                                    <ButtonComponent action="Login" disabled={!this.state.FormStatus} CallBack={(e) => this.Login()}></ButtonComponent>                                              
                                                </div> 

                                                {this.state.Error.status === true && (
                                                    <div>
                                                        <i className="fa-sharp fa-regular fa-circle-exclamation fa-fw"></i>  <font className="is-valid-feedback">{this.state.Error.message}</font>
                                                    </div>
                                                )}                                                     
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;