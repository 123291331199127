import React , {Component} from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Alert from 'react-bootstrap/Alert';

import Image from 'react-bootstrap/Image';

// import error404 from '../../images/404.jpg';

import noResult from '../../images/404.jpg';

import LoginBG from '../../images/DIT-BG.png';

class AlertComponent extends Component{

    render(){

        let title, bg, msg, hint;
        let type = this.props.type;
        let jsxOutput, icon;

        // console.log(type);

        if(type === 'NoResult'){
            title = "ผลการค้นหา";
            //icon = "fa-solid fa-triangle-exclamation fa-fw";
            bg = "primary";
            msg = "ไม่พบข้อมูล";
            hint = "ระบบยังไม่มีข้อมูล หรือ กรุณาเปลี่ยนเงื่อนไขการค้นหา";  
        }else if( type === 'FileNotFound'){
          
            title="เกิดข้อผิดพลาด 404"
            bg="primary"
            msg="ไม่พบหน้าจอที่ร้องขอ"
            hint="กรุณาระบุการเข้าถึงให้ถูกต้อง"                   
                
        }else if(type === 'ImportNoResult'){
            /** 
            title="ข้อมูลนำเข้าจากไฟล์";
            bg="danger";
            msg="ไม่พบข้อมูล";
            hint="ระบบยังไม่มีข้อมูล หรือ ข้อมูลไม่สามารถนำเข้าได้";                
            */     
        }

        if(type === 'FileNotFound'){
            /** 
            jsxOutput = (
                <Row className="align-items-center justify-content-center" >
                    <Col lg={12}>     
                        <Image src={error404} fluid />
                    </Col>
                </Row>               
            );
            */

            jsxOutput = (
                <div className="login-main-page" style={{backgroundImage:"url("+ LoginBG+")"}}>     
                    <div style={{minHeight: '720px'}}>
                    <Row>
                        <Col md={{span: 6, offset: 3}}> 
                                            
                            <Alert variant={bg}>
                                <Alert.Heading><i className={icon}></i> {title}</Alert.Heading>
                                <p style={{color: '#bbb'}}>{msg}</p>
                                <hr></hr>
                                <p className="mb-0" style={{color: '#bbb'}}>{hint}</p>
                            </Alert>
                            </Col>
                    </Row>  
                    </div>
                </div>                       
                );            
                        
        }else if(type === 'NoResult'){
            jsxOutput = (   
                <>
                    <Row className="text-center" >
                        <Col>     
                            <Image src={noResult} fluid />
                        </Col>
                    </Row>                    
                </>                
            );
            
            jsxOutput = (
                <>
                    <br></br>
                        <Alert variant={bg}>
                        <Alert.Heading><i className={icon}></i> {title}</Alert.Heading>
                        <p style={{color: '#bbb'}}>{msg}</p>
                        <hr></hr>
                        <p className="mb-0" style={{color: '#bbb'}}>{hint}</p>
                    </Alert>                    
                </>
                );            
            
        }else if(type === 'ImportNoResult'){ jsxOutput = ""; }

        return ( <>{jsxOutput}</> )
    }
}
export default AlertComponent;        