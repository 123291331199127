import React, {Component} from "react";

import NavHeader from './NavHeader';
import SideBar from './SideBar';
import Header from './Header';

class Nav extends Component {

    render(){
        return (
            <>
                <NavHeader></NavHeader>
                <SideBar></SideBar>
                <Header></Header>
            </>
        );
    }
}
export default Nav;