import React , {Component} from "react";

class Loading extends Component{

    render(){
        return (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                </div>
        );
    }
}

export default Loading;
