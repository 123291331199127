import React, {Component} from "react";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import * as StandardTask from '../utilities/StandardTask';

class ModalComponent extends Component {

    callback = (action, bl) => { if (this.props.CallBack instanceof Function) { this.props.CallBack(action, bl); } }

    render(){        
        
        let modalObject = this.props.modal;
        let toggle = modalObject.Toggle;
        let action = modalObject.Action;

        let title, body, discard, operation, operationClass, icon;

        let classInline = ' btn-' + StandardTask.getElementsSize();

        operationClass = "primary";

        if(action === 'Entry'){            
            title = "บันทึกข้อมูล";
            body = "ยืนยันการบันทึกข้อมูล";
            operation = "บันทึก";
            discard = "ยกเลิก";
        }else if(action === 'Edit'){

        }else if(action === 'Delete'){
            title = "ลบข้อมูล";
            body = "ยืนยันการลบข้อมูล";                        
            operation = "ลบข้อมูล";
            icon = "fa-solid fa-trash-check fa-fw"; 
            operationClass = "danger";
            discard = "ยกเลิก";              
        }else if(action === 'ChangePassword'){
            title = "เปลี่ยนรหัสผ่าน";
            body = "ยืนยันการเปลี่ยนรหัสผ่าน";                    
            operation = "เปลี่ยนรหัสผ่าน";
            icon = "fa-solid fa-shield-check fa-fw";
            discard = "ยกเลิก";   
        }else if(action === 'FieldRequired'){
            title = "ติดตามการขนย้ายสินค้าเกษตร";
            body = "กรุณาระบุเงื่อนไขการค้นหา";                    
            operation = "";
            icon = "fa-solid fa-shield-check fa-fw";
            discard = "ยกเลิก";   
        }else if(action === 'DateRangeRequired'){
            title = "ติดตามการขนย้ายสินค้าเกษตร";
            body = "กรุณาระบุ วันที่เริ่มต้นขนย้าย และ วันที่สิ้นสุดการขนย้าย";                    
            operation = "";
            icon = "fa-solid fa-shield-check fa-fw";
            discard = "ยกเลิก";   
        }else if(action === 'FilterRequired'){
            title = "ติดตามการขนย้ายสินค้าเกษตร";
            body = "กรุณาระบุ ประเภทสินค้า ที่ต้องการติดตาม";                    
            operation = "";
            icon = "fa-solid fa-shield-check fa-fw";
            discard = "ยกเลิก";   
        }           

        return (
            <Modal show={toggle} backdrop="static" centered onHide={() => this.callback()}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>     
                <Modal.Body>{body}</Modal.Body> 
                <Modal.Footer>
                    <Button variant="warning" className={classInline} onClick={() => this.callback(action, false)}><i className="fa-solid fa-xmark fa-fw"></i>{discard}</Button>
                    {operation !== '' ? 
                    <Button variant={operationClass} className={classInline}  onClick={() => this.callback(action, true)}><i className={icon}></i> {operation}</Button>
                    :''}
                </Modal.Footer>                            
            </Modal>
        )
    }
}

export default ModalComponent;