import React , {Component} from "react";

import Button from 'react-bootstrap/Button';
// import Badge from 'react-bootstrap/Badge';

// import * as StandardTask from '../utilities/StandardTask';

class ButtonComponent extends Component{

    callback = (e) => {
        if (this.props.CallBack instanceof Function) { this.props.CallBack(e);}
    }

    render(){

        let action = this.props.action;
        let disabled = this.props.disabled;
        let size = this.props.size;
        
        /**
        let custom = this.props.custom;        
        let varianted = this.props.varianted;
        let badge = this.props.badge;
        */

        if(disabled === '' || disabled === undefined){ disabled = false;}
        if(size === '' || size === undefined){ size = false;}

        let variant = "";
        let label = "";
        let classInline = "float-end";    
        let jsxStyle = {};             
        // let jsxBadge = "";   
        
        let icon = "";

        if(action === "New"){            
            label = "เพิ่มข้อมูล";
            variant = "light";
            icon = "fa-solid fa-circle-plus fa-fw";
            // classInline = " btn-light ";
        }else if(action === "Save" || action === "ChangePassword" ){        
            // || action === "Login"    
            if(action === "Save"){label = "บันทึก"; icon = "fa-solid fa-circle-check fa-fw";}
            else if(action === "ChangePassword"){label = "เปลี่ยนรหัสผ่าน"; icon = "fa-solid fa-lock fa-fw"; }
            // else if(action === "Login"){label = "เข้าสู่ระบบ"; icon = "fa-solid fa-right-to-bracket fa-fw"; }                                    
            variant = "success";
        }else if(action === "Edit"){            
            // label = "แก้ไข";
            variant = "light";
            classInline = "";              
            // jsxStyle = {width: '100%'};
            icon = "fa-solid fa-pen fa-fw";
        }else if(action === "Search"){
            label = "ค้นหา";
            variant = "primary";
            classInline += " me-2";  
            icon = "fa-solid fa-magnifying-glass fa-fw";           
        }else if(action === "Discard"){
            label = "ยกเลิก";
            variant = "warning";
            classInline += " me-2";   
            icon = "fa-solid fa-rotate-left fa-fw";           
        }else if(action === "Delete"){            
            // label = "ลบ";
            variant = "danger";  
            // classInline = "btn-sm";  
            icon = "fa-solid fa-trash fa-fw";
        }else if(action === "Back"){
            label = "ย้อนกลับ";
            variant = "light";   
            icon = "fa-solid fa-circle-arrow-left fa-fw";            
        }else if(action === "ForgotPassword"){
            label = "ลืมรหัสผ่าน";
            variant = "danger";
            classInline += " me-2";  
            icon = "fa-solid fa-user-shield fa-fw";  
        }else if(action === "RandomPassword"){
            label = "สร้างรหัสผ่าน";
            variant = "primary";
            classInline = " mb-1";  
            icon = "fa-solid fa-wand-magic-sparkles fa-fw";  
        }else if(action === "Download"){
            // label = "ดาวน์โหลด";
            variant = "primary";
            // classInline = " btn-sm"; 
            icon = "fa-solid fa-cloud-arrow-down fa-fw";              
        }else if(action === "FileDownload"){   
            label = "ดาวน์โหลดไฟล์ | " + this.props.filename;
            variant = "primary";   
            classInline += "";
            icon = "fa-solid fa-cloud-arrow-down fa-fw";
        }else if(action === "Login"){
            label = "เข้าสู่ระบบ";
            classInline += " btn-block";
            variant = "primary";
        }

        jsxStyle = {whiteSpace: 'nowrap'};

        if(action === 'ChangePassword'){ jsxStyle = {whiteSpace: 'nowrap', width: '100%'}; }

        classInline += ' btn-sm';

        // , fontSize: StandardTask.getElementsSize()
        // console.log(StandardTask.getElementsSize());

        // classInline += " btn-light";
        /** 
        else if(action === "Custom"){
            label = custom;
            variant = varianted;
            classInline = "btn";
            jsxStyle = {fontSize: '0.8rem'};
        }
        if( badge !== '' && badge !== undefined){
            jsxBadge = (<Badge bg="dark">{badge}</Badge>);            
        }
        */        

        return (
            <>
            <Button variant={variant} className={classInline} disabled={disabled} style={jsxStyle} size={size} onClick={(e) => this.callback(e)}><i className={icon}></i> {label}</Button>

            </>
            
           
        )
    }
}
export default ButtonComponent;