import React, {Component} from "react";

import AlertComponent from './components/AlertComponent';

class FileNotFound extends Component {

    render(){
        return (
            <>
                 <AlertComponent type="FileNotFound"></AlertComponent>
            </>
        );
    }
}
export default FileNotFound;