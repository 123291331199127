import React, {Component} from "react";

import axios from 'axios';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form  from 'react-bootstrap/Form';

import { FormGroup } from "react-bootstrap";

import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import AlertComponent from './components/AlertComponent';

import ButtonComponent from './components/ButtonComponent';

import PaginationComponent from './components/PaginationComponent';

import SelectListComponent from './components/SelectListComponent';

import ModalComponent from './components/ModalComponent';

import DatePickerThComponent from './components/DatePickerThComponent';

import DataLoadingComponent from './components/DataLoadingComponent';

import * as StandardTask from './utilities/StandardTask';

class Monitoring extends Component {

    API_DEFAULT_END_POINT = process.env.REACT_APP_API_END_POINT;
    API_END_POINT = this.API_DEFAULT_END_POINT + "monitoring/";

    itemArrayDelete = [];

    state = {
        Control: {
            Title: 'ติดตามการขนย้ายสินค้า',
            Icon: 'fa-sharp fa-regular fa-truck fa-fw',
            Init: 'Init',
            Mode: 'View',
            Page: '1',
            Modal: {Action: 'View', Toggle: false},
            Toast: {Delay: 800}
        },
        DataTable : {
            data: [],
            info: {}
        },
        Search: {        
            userId: {
                id: 'userId',
                value: StandardTask.getUserProfile().id
            },            
            productItem: {
                id: 'productItem',
                label: 'ประเภทสินค้า',
                value: ''
            },
            keywordSearch: {
                id: 'keywordSearch',
                label: 'คำค้น',
                value: ''
            },              
            startDate: {
                id: 'startDate',
                label: 'เริ่มต้นขนย้าย',
                value: ''
            },            
            endDate: {                
                id: 'endDate',
                label: 'สิ้นสุดการขนย้าย', 
                value: ''
            }
        },
        MasterDataProductItem: {},   
        Loading: false,    
        Debug: false     
    };

    StagingControl = (action, key, value) => { this.setState(prev => {return {[key]: value} }, () => { if(this.state.Debug === true){ console.log( action + " : ", this.state );} });}
    
    FlowControl = (action) => { if((action === 'Delete' && this.state.Delete.length > 0) || action !== 'Delete'){ const ControlElements = StandardTask.FlowController(action, {...this.state.Control}); this.StagingControl('FlowControl - ' + action , 'Control', ControlElements); } } 

    ModalControl = (action, bl) => {
        let ControlElements = StandardTask.ModalController(action, {...this.state.Control});
        this.setState(prev => {return {Control: ControlElements} }, () => {
            if(this.state.Debug === true){ console.log("ModalClose - " + action + " : ", this.state.Control); }
            if(bl === true){ if(action === 'Delete'){this.Delete();} }    
        });        
    }    

    PagingControl = (page) => {
        let ControlElements = StandardTask.PagingController({...this.state.Control}, page);
        this.setState(prev => {return {Control: ControlElements} }, () => { if(this.state.Debug === true){ console.log("Pagination : ", this.state.Control);} this.DataTable(); });          
    }    

    SearchChange = (e) => { const SearchElements = StandardTask.SearchController({...this.state.Search}, e.target.name, e.target.value); this.StagingControl('SearchChange' , 'Search', SearchElements); }

    Search = () => {
        let ControlElements = StandardTask.SearchPrompt({...this.state.Control});
        this.setState(prev => {return {Control: ControlElements} }, () => { if(this.state.Debug === true){ console.log("Search : Control - ", this.state.Control, " Filter : ", this.state.Search);} this.DataTable(); });         
    }

    DataTable = async () => {

        const params = StandardTask.SearchParams({...this.state.Control}, {...this.state.Search});
        
        if ( params.params.productItem === '' && params.params.keywordSearch === '' && params.params.startDate === '' && params.params.endDate === '' ){
            this.FlowControl('FieldRequired');
        }else if( (params.params.startDate !== '' && params.params.endDate === '') ||  (params.params.startDate === '' && params.params.endDate !== '') ){
            this.FlowControl('DateRangeRequired');  
        }else if( (params.params.productItem === '') ){
            this.FlowControl('FilterRequired');                         
        }else {
            this.StagingControl('Loading' , 'Loading', true);
            await axios.get( this.API_END_POINT + "pagination", params)
            .then((res)=>{ 
                this.StagingControl('DataTable', 'DataTable', {data: res.data.result, info: res.data.info});
                this.StagingControl('Loading' , 'Loading', false); 
            })
            .catch((err)=>{ if(this.state.Debug === true){ console.log("DataTable.Error : ", err)} });
        }
    }  

    LoadMasterData = async () => {
        let ProductItemElements = {};

        await axios.get(this.API_DEFAULT_END_POINT + "dataset-master/list-product-item/")
        .then((res)=>{
            res.data.result.forEach((item, index) => { ProductItemElements[index] = {id: item.id, value: item.name} });
            this.setState(prev => {return {MasterDataProductItem: ProductItemElements} }, () => { if(this.state.Debug === true){ console.log("LoadProductItem : ", this.state.MasterDataProductItem);} });              
        }).catch((err)=>{ if(this.state.Debug === true){ console.log("LoadProductItem.Error : ", err) }});       
    }

    DatePickerChange = (id, datePicked) => {
        const SearchElements = StandardTask.SearchController({...this.state.Search}, id, datePicked); this.StagingControl('DateChange' , 'DatePicker', SearchElements);        
    };

    componentDidMount = () => { 
        // this.DataTable(); 
        this.LoadMasterData(); 
    }

    render(){

        let Control = this.state.Control;
        let Search = this.state.Search;
        // let Input = this.state.Input;

        const DataTable = this.state.DataTable; 
        let rowId = ((DataTable.info.currentPage * DataTable.info.pageSize) - DataTable.info.pageSize) + 1;   

        //let recordCount = 0; 

        let size = StandardTask.getElementsSize();

        let userProfile = StandardTask.getUserProfile();
        // console.log("userProfile", userProfile);

        let jsxDataTable = DataTable.data.map((item, i) => {
            //recordCount++;

            return(
                <tr key={i}>
                    <td className="td-center">{rowId++}</td>
                    <td>
                            {userProfile.department === '1' ? 
                            item.productItemName
                            :
                            item.productName 
                            }
                    </td>
                    {userProfile.department === '1' ?
                        <>
                            <td>
                                <i className="fa-sharp fa-solid fa-file-certificate fa-fw"></i>&nbsp;
                                <a href={'https://transport.dit.go.th/app/' + item.transportLicenseFile} style={{textDecoration: 'underline'}} target="_blank" rel="noopener noreferrer">
                                    {item.transportLicense}
                                </a>                                
                            </td>
                            <td>{item.requesterName}</td>                            
                        </>
                    :''}
                    <td>
                        {userProfile.department === '1' ? 
                           <>{item.sourceName}<br></br></> 
                        :
                        <>
                            { item.sourceName.includes('ศุลกากร') ? 
                            <> {item.sourceName} <br></br></> : ''}
                            {/**item.sourceProvinceName !== '' || item.sourceAmphurName !== '' ? <br></br> : ''*/}
                        </>
                        }
                        {item.sourceAmphurName !== '' ? 'อ.' + item.sourceAmphurName + ' ': ''}
                        {item.sourceProvinceName !== '' ? 'จ.' + item.sourceProvinceName + ' ': ''}
                    </td>
                    <td>
                        {userProfile.department === '1' ? 
                           <>{item.destinationName}<br></br></> 
                        :
                        <>
                            { item.destinationName.includes('ศุลกากร') ? 
                            <> {item.destinationName} <br></br></> : ''}
                        </>
                        }
                        {/**item.destinationProvinceName !== '' || item.destinationAmphurName !== '' ? <br></br> : ''*/}
                        {item.destinationAmphurName !== '' ? 'อ.' + item.destinationAmphurName + ' ': ''}
                        {item.destinationProvinceName !== '' ? 'จ.' + item.destinationProvinceName + ' ': ''}                            
                    </td>
                    <td style={{textAlign: 'right'}}>{StandardTask.FormatNumber(item.quantity)}</td>
                    <td className="td-center">{StandardTask.DateTime(item.startDateTime , 1)} <br></br> {StandardTask.DateTime(item.endDateTime, 1)}</td>
                    <td>{item.licenseCode}</td>
                </tr>
            )
        });  

        return (
            <>             
                <ModalComponent modal={Control.Modal} CallBack={(action, bl) => this.ModalControl(action, bl)}></ModalComponent>    

                <div className="form-head mb-4">
                    <h2 className="text-black font-w400 mb-0">
                        <i className={Control.Icon}></i> {Control.Title}                      
                    </h2>
                </div>             

                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>

                            {Control.Mode === 'View' ?                                
                                <>
                                    <Row>
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>ประเภทสินค้า</label>
                                                <SelectListComponent 
                                                    id={Search.productItem.id} 
                                                    name={Search.productItem.id} 
                                                    value={Search.productItem.value} 
                                                    dataSet="object"
                                                    objectData={this.state.MasterDataProductItem}                                                         
                                                    CallBack={(e) => this.SearchChange(e)}
                                                ></SelectListComponent>                                      
                                        </Col>
                                        <Col className="form-group mb-3" lg={4}>
                                            <label>คำค้น</label>
                                                <Form.Control
                                                    type="text" 
                                                    id={Search.keywordSearch.id} 
                                                    name={Search.keywordSearch.id} 
                                                    defaultValue={Search.keywordSearch.value}
                                                    placeholder={Search.keywordSearch.label}
                                                    onChange={(e) => this.SearchChange(e)}  
                                                    size={size}                                        
                                                ></Form.Control>                                           
                                        </Col>  
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>เริ่มต้นขนย้าย</label>
                                            <FormGroup>
                                                <DatePickerThComponent
                                                    id="startDate"
                                                    CallBack={this.DatePickerChange}                                             
                                                />       
                                            </FormGroup>                                 
                                        </Col>                                         
                                        <Col className="form-group mb-3" lg={2}>
                                            <label>สิ้นสุดการขนย้าย</label>
                                            <FormGroup>
                                                <DatePickerThComponent
                                                    id="endDate"
                                                    CallBack={this.DatePickerChange}                                          
                                                />       
                                            </FormGroup>                                            
                                        </Col>                                            
                                        <Col className="form-group mb-3" lg={2}  style={{ alignSelf: 'end' }}>
                                            <ButtonComponent action="Search" CallBack={(e) => this.Search()}></ButtonComponent>
                                        </Col>                                                                     
                                    </Row>                              

                                    {DataTable.info.totalRows === 0 || DataTable.info === undefined || DataTable.info.totalRows === undefined  ? 
                                       this.state.Loading === false ? 
                                        <AlertComponent type="NoResult"></AlertComponent>
                                        :''                                       
                                    :
                                    <>
                                        {this.state.Loading ? <DataLoadingComponent/> : 
                                        <>
                                            <Table responsive hover >
                                                <thead>
                                                    <tr key="thead-0">
                                                        <th className="th-row th-center"><i className="fa-solid fa-hashtag fa-fw"></i></th>
                                                        <th style={{minWidth: '150px'}}>ประเภทสินค้า</th>
                                                        {userProfile.department === '1' ?
                                                        <>
                                                            <th style={{minWidth: '165px'}}><i className="fa-sharp fa-solid fa-file-certificate fa-fw"></i>ใบอนุญาต</th>
                                                            <th style={{minWidth: '200px'}}><i className="fa-solid fa-user-tie fa-fw"></i> ผู้ขออนุญาต</th>
                                                        </>
                                                        :''} 
                                                        <th style={{minWidth: '275px'}}><i className="fa-solid fa-right-from-line fa-fw"></i> ต้นทาง</th>
                                                        <th style={{minWidth: '275px'}}><i className="fa-solid fa-location-dot fa-fw"></i> ปลายทาง</th>
                                                        <th style={{textAlign: 'center', minWidth: '100px'}}><i className="fa-sharp fa-solid fa-boxes-stacked fa-fw"></i> ปริมาณ</th>
                                                        <th style={{textAlign: 'center', minWidth: '155px'}}><i className="fa-solid fa-clock fa-fw"></i> ระยะเวลา</th>
                                                        <th style={{minWidth: '200px'}}><i className="fa-regular fa-truck-fast fa-fw"></i> ทะเบียนรถ</th>                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {jsxDataTable}
                                                </tbody>                                        
                                            </Table>

                                            <PaginationComponent info={DataTable.info} CallBack={(page) => this.PagingControl(page)}></PaginationComponent>
                                        </>
                                    }
                                    </>
                                    }
                                </>
                            :''}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                   

            </>
        );
    }
}
export default Monitoring;