import React, {Component} from "react";

import Index from './jsx/Index';

import 'react-toastify/dist/ReactToastify.css';
import "./css/style.css";

class App extends Component {
  render(){
    return (
        <>
          <Index/>
        </>
    )
  }
}

export default App;