import React , {Component} from 'react';

import Table from 'react-bootstrap/Table';

import BadgeComponent from './BadgeComponent';

import Pagination from 'react-bootstrap/Pagination';

import * as StandardTask from '../utilities/StandardTask.js';

class PaginationComponent extends Component {

    callback = (page) => { if (this.props.CallBack instanceof Function) {this.props.CallBack(page);} }

    render(){
        
        const pageSize = this.props.info.pageSize;
        const currentPage =  this.props.info.currentPage;
        const totalPage = this.props.info.totalPage;
        const totalRows = this.props.info.totalRows;

        let currentSection = this.props.info.currentSection;
        let totalSection = this.props.info.totalSection;
        let startPage = (((currentSection - 1) * pageSize) + 1);

        /** overwrite totalSection, currentSection, startPage */
        const pagesPerSection = 10;
        totalSection = Math.ceil(totalPage/pagesPerSection);
        currentSection = Math.ceil(currentPage/pagesPerSection);
        startPage = (currentSection - 1) * pagesPerSection + 1;
        let endPage = Math.min(startPage + pagesPerSection -1, totalPage);

        let runnerPage = startPage;

        // console.log(currentPage, totalRows, pageSize, totalPage, totalSection, currentSection);

        const paginationList = [];
        let active = false;
        
        for(let i = startPage, clickPage = runnerPage ; i <= endPage; i++, clickPage++, runnerPage++){
            // if( runnerPage > totalPage ){ break; }
            parseInt(runnerPage) === parseInt(currentPage) ? active = true : active = false;
            paginationList.push(<Pagination.Item key={clickPage} active={active} onClick={(e) => this.callback(clickPage)}>{runnerPage}</Pagination.Item>);
        }

        let paginationSize = 'pagination-' + StandardTask.getElementsSize();
    
        return (
            <>
                <Table responsive size="sm" style={{marginTop: '0px'}}>
                    <tr>
                        <td>
                        {totalPage > 0 ?
                            <>
                                <i className="fa-solid fa-bars-sort fa-fw"></i>
                                <BadgeComponent
                                    bg="primary"
                                    value={StandardTask.FormatNumber(totalRows)}
                                    label="แถวข้อมูล"              
                                ></BadgeComponent>            
                            </>                    
                            :''}
                            <Pagination className={ paginationSize + " pagination-circle justify-content-end mt-4 float-end"}>
                                {currentSection > 1 ? <Pagination.Item key={startPage-1} onClick={(e) => this.callback(startPage-1)}><i className="fa-solid fa-arrow-left fa-fw"></i></Pagination.Item> : ''}
                                {paginationList}
                                {currentSection < totalSection ? <Pagination.Item key={endPage+1} onClick={(e) => this.callback(endPage+1)}><i className="fa-solid fa-arrow-right fa-fw"></i></Pagination.Item> : ''}
                            </Pagination>                              
                        </td>
                    </tr>
                </Table>

       
            </>
        )
    }
}

export default PaginationComponent;