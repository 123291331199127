import React , {Component} from "react";

class DataLoadingComponent extends Component{

    render(){
        return (
            <small style={{color: 'white'}}><i className="fa-duotone fa-spinner-third fa-fw rotate-icon" style={{fontSize: '1rem'}}></i> ค้นหาข้อมูล...</small>
        );
    }
}

export default DataLoadingComponent;
