import React, {Component} from "react";
import {BrowserRouter, Routes, Route, Outlet} from 'react-router-dom';

import Nav from './layouts/Nav';
import Footer from './layouts/Footer';

import Login from './Login';

// import Home from './Home';

import ChangePassword from './ChangePassword';

import Monitoring from './Monitoring';

import UserAccount from './UserAccount';

import MonitoringTracking from './MonitoringTracking';

import FileNotFound from './FileNotFound';

import './index.css';

import * as StandardTask from './utilities/StandardTask';

class Index extends Component {

    REDIRECT_URL = process.env.REACT_APP_URL_ROOT;

    state = {
        userToken : ''
    }

    allRoutes = [
        // {url: 'Home', component: <Home></Home>}, 
        {url: 'Monitoring', component: <Monitoring></Monitoring>},         
        {url: 'UserAccount', component: <UserAccount></UserAccount>}, 
        {url: 'ChangePassword', component: <ChangePassword></ChangePassword>}, 

        {url: 'MonitoringTracking', component: <MonitoringTracking></MonitoringTracking>},    
    ];

    MainLayout = () => {
        return (
            <div id = "main-wrapper" className="show">
                <Nav></Nav>
                <div className="content-body" style={{minHeight: window.screen.height - 45}}>
                    <div className="container-fluid">
                        <Outlet></Outlet>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }

    componentDidMount = async () => {
        let userToken = StandardTask.getCookieByName("ditUserToken");        
        let path = window.location.pathname;

        // userToken = 'asanaToken';

        if( (userToken === '' || userToken === null || userToken === undefined) ){
            if(path !== '/'){return window.location.href = this.REDIRECT_URL;}
        }else{
            let userProfile = StandardTask.getUserProfile();
            if( userProfile.department === '2' && path === '/UserAccount'){
                return window.location.href = this.REDIRECT_URL;
            }else{
                this.setState(prev => {return {userToken: userToken} });
            }    
        }
    }

    render(){
        
        let userToken = this.state.userToken;

        return (
            <>
                { (!(userToken === '' || userToken === null || userToken === undefined)) ? 
                    <BrowserRouter>
                        <Routes>
                            <Route path='/' element={<Login/>}/> 

                            <Route path='/MonitoringTracking' element={<MonitoringTracking/>} />

                            <Route element={this.MainLayout()}>
                                {this.allRoutes.map((data,i) => (                         
                                    <Route key={i} exact path={data.url} element={data.component}></Route>
                                ))}
                            </Route> 
                            <Route path='*' element={<FileNotFound/>}/>                                                        
                        </Routes>           
                    </BrowserRouter>                
                :
                    <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Login/>}/> 
                    </Routes>           
                    </BrowserRouter>                   
                } 
            </>
        );
    }
}
export default Index;