import React, {Component} from "react";

class Footer extends Component {

    render(){
        return (
            <>
                <div className="footer">
                    <div className="copyright">
                        <p style={{fontSize: '0.8rem'}}>
                            Copyright © DIT.GO.TH <small style={{fontSize: '0.5rem'}}>&amp; Developed by GROW UP TECH CO., LTD.</small>
                        </p>
                    </div>
                </div>
            </>
        );
    }
}
export default Footer;