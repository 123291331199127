
export const AppInfo = {
    AppName: 'OTEPC',
    Title: 'สำนักงาน ก.ค.ศ.',
    Footer: 'POWERED BY GROW UP TECH CO.,LTD.'    
}

export const AppMenu = [
    {
        title: 'ติดตามการขนย้ายสินค้า',
        classChange: 'mm-show',
        iconStyle: <i className="fa-sharp fa-regular fa-truck fa-fw"></i>,
        to: '/Monitoring',
        target: ''
    },
    {
        title: 'ตรวจสอบการเรียกข้อมูล',
        classChange: 'mm-show',
        iconStyle: <i className="fa-sharp fa-regular fa-truck fa-fw"></i>,
        to: '/MonitoringTracking',
        target: '_blank'
    },    
    {
        title: 'ผู้ใช้งานระบบ', 
        classChange: 'mm-show',
        iconStyle: <i className="fa-solid fa-user-pilot-tie fa-fw'"></i>,
        to: '/UserAccount',
        target: ''    
    }      
];

export const BreadcrumbsList = {
    Dashboard : {
        Label: 'Dashboard', 
        Path: ''
    },
    ApiImport: {
        Label: 'API และ Import', 
        API: [{label: 'API', path: 'API', active: true}],             
        Import: [{label: 'Import', path: 'Import', active: true}]    
    },  
    Account: {
        Label: 'ผู้ใช้งานระบบ', 
        UserGroup: [{label: 'ระดับสิทธิ์', path: 'UserGroup', active: true}],             
        UserAccount: [{label: 'ผู้ใช้งาน', path: 'UserAccount', active: true}]      
    },
    AccountOption: {
        Label: 'บัญชีผู้ใช้งาน', 
        UserProfile: [{label: 'บัญชีผู้ใช้งาน', path: 'UserProfile', active: true}],    
        ChangePassword: [{label: 'เปลี่ยนรหัสผ่าน', path: 'ChangePassword', active: true}],      
    },        
    MasterData : {
        Label: 'การตั้งค่า',
        HrmsObecSetting: [{label: 'API Token', path: 'HrmsObecSetting', active: true}],         
        SpecificZone: [{label: 'พื้นที่พิเศษ', path: 'SpecificZone', active: true}],             
        SchoolAttribute: [{label: 'ลักษณะสถานศึกษา', path: 'SchoolAttribute', active: true}],                 
        EducationZone: [{label: 'เขตพื้นที่การศึกษา', path: 'EducationZone', active: true}],  
        AreaBase: [{label: 'สังกัด', path: 'AreaBase', active: true}],                    
        Region: [{label: 'ภูมิภาค', path: 'Region', active: true}], 
        Province: [{label: 'จังหวัด', path: 'Province', active: true}],
        District: [{label: 'อำเภอ', path: 'District', active: true}]                                     
    }    
}

export const StandardData = {
    MonthTH : [
        {id: '01', fullname: 'มกราคม', name: 'ม.ค.'},
        {id: '02', fullname: 'กุมภาพันธ์', name: 'ก.พ.'},     
        {id: '03', fullname: 'มีนาคม', name: 'มี.ค.'},
        {id: '04', fullname: 'เมษายน', name: 'เม.ย.'},
        {id: '05', fullname: 'พฤษภาคม', name: 'พ.ค.'},
        {id: '06', fullname: 'มิถุนายน', name: 'มิ.ย.'},     
        {id: '07', fullname: 'กรกฎาคม', name: 'ก.ค.'},
        {id: '08', fullname: 'สิงหาคม', name: 'ส.ค.'},
        {id: '09', fullname: 'กันยายน', name: 'ก.ย.'},
        {id: '10', fullname: 'ตุลาคม', name: 'ต.ค.'},     
        {id: '11', fullname: 'พฤศจิกายน', name: 'พ.ย.'},
        {id: '12', fullname: 'ธันวาคม', name: 'ธ.ค.'},                
    ]
}

export const RawDataStatus = {
    '0' : 'สมบูรณ์',
    '1' : 'ซ้ำซ้อน',
    '2' : 'ไม่สมบูรณ์',
    '3' : 'นำเข้าไม่สำเร็จ'
}

export const StyledDataGridConfig = {
        backgroundColor: 'rgba(255,255,255,.85)',
        color:  'rgba(0,0,0,.85)', 
        marginTop: '16px',            
        borderRadius: '8px',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor:  '#fafafa',
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          borderRight: `1px solid ${
             '#999999'
          }`, 
          borderBottom: `1px solid ${
            '#999999'
         }`,                       
        },                      
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
          borderBottom: `1px solid ${
             '#999999'
          }`,
        },
        '& .MuiDataGrid-cell': {
          color:
             'rgba(0,0,0,0.65)',
        },                
}