import {StandardData, RawDataStatus} from '../../config/AppConfig.js';

const MonthArray = {};
const MonthTH = StandardData.MonthTH;
Object.keys(MonthTH).forEach((item, i) => { MonthArray[MonthTH[item].id] = MonthTH[item].name });

export const GetInputClass = (InputElements, name, option) => {
    let value = String(InputElements[name].value).trim();
    const error = InputElements[name].error.status;
    
    let inputClass = '';

    if( value.length > 0 || option === 'file'){
        if(error){ inputClass = 'is-invalid'; }
        else{ if(value.length > 0){ inputClass = "is-valid"; }}
    }
    return inputClass;
}

export const GetErrorMessage = (InputElements, name) => { return InputElements[name].error.msg; }

export const SearchController = (SearchElements, name, value) => { SearchElements[name].value = value; return SearchElements; }

export const SearchPrompt = (ControlElements) => { ControlElements.Init = 'Init'; ControlElements.Page = '1'; return ControlElements; }

/** verify : 130666 */
export const InputController = (InputElements, name, value, required) => {
    let FormStatus = true;
    let validatorObject = {}
    let input, status; 

    InputElements[name].value = value;
    
    if(required){
        validatorObject = ValidatorInput(value, InputElements[name].validator);
        InputElements[name].error = {status: validatorObject.status, msg: validatorObject.msg};

        for (let name in InputElements){
            input = InputElements[name].value;
            status = InputElements[name].error.status;

            if(InputElements[name].validator.required === true){
                if(status === true){ FormStatus = false; break; }
                else{
                    if(input === ''){ FormStatus = false; break; }
                    else{ FormStatus = true; }
                }                
            }
        }
    }
    return {InputElements, FormStatus};
}

/** verify : 130666 */
export const FlowController = (action, ControlElements) => {
    // console.log(action, ControlElements);
    if(action === 'New'){
        ControlElements.Mode = 'Entry';
        ControlElements.Modal.Action = 'Entry';        
    }else if(action === 'Edit'){
        ControlElements.Mode = 'Edit';
        ControlElements.Modal.Action = 'Edit'; 
    }else if(action === 'Delete'){
        ControlElements.Modal.Action = 'Delete';                   
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;                         
    }else if(action === 'FormDiscard'){
        ControlElements.Mode = 'View';
        ControlElements.Modal.Action = 'View';
    }else if(action === 'ChangePassword'){
        ControlElements.Modal.Action = 'ChangePassword';                   
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;        
    }else if(action === 'Task'){
        ControlElements.Mode = 'Task';        
        ControlElements.Modal.Action = 'Task';                      
    }else if(action === 'FieldRequired'){
        ControlElements.Mode = 'View';        
        ControlElements.Modal.Action = 'FieldRequired';  
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;                    
    }else if(action === 'DateRangeRequired'){
        ControlElements.Mode = 'View';        
        ControlElements.Modal.Action = 'DateRangeRequired';  
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;                    
    }else if(action === 'FilterRequired'){
        ControlElements.Mode = 'View';        
        ControlElements.Modal.Action = 'FilterRequired';  
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;                    
    }
    return ControlElements;
}

/** verify : 130666 */
export const ModalController = (action, ControlElements) => {
    if(action === 'Delete'){
        ControlElements.Modal.Action = 'View'; 
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;                      
    }else if(action === 'ChangePassword'){
        ControlElements.Modal.Action = 'View';         
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;   
    }else if(action === 'FieldRequired' || action === 'DateRangeRequired' || action === 'FilterRequired'){
        ControlElements.Modal.Action = 'View';         
        ControlElements.Modal.Toggle = ! ControlElements.Modal.Toggle;   
    }
     
    return ControlElements;
}

export const PagingController = (ControlElements, page) => { ControlElements.Init = 'Init'; ControlElements.Page = page; return ControlElements; }

export const CommitController = (ControlElements) => { ControlElements.Mode = 'View'; ControlElements.Modal.Action = 'View'; return ControlElements; }

export const importDataFileInfoController = (DataInfoElements, data) => {
    DataInfoElements.dataYear = parseInt(data.dataYear) + 543;
    DataInfoElements.dataTypeId = parseInt(data.dataTypeId);      
    DataInfoElements.dataType = data.dataType;    
    DataInfoElements.dataScope = data.dataScope;
    DataInfoElements.provinceName = data.provinceName;
    DataInfoElements.reference = data.reference;
    DataInfoElements.importDate = DateTime(data.importDate);
    DataInfoElements.rows = data.rows;
    DataInfoElements.errorRows = data.errorRows;
    DataInfoElements.warningRows = data.warningRows;
    DataInfoElements.duplicateRows = data.duplicateRows;
    // DataInfoElements.errorCount = data.errorCount;
    return DataInfoElements;
}

export const apiInfoController = (DataInfoElements, data) => {
    DataInfoElements.dataYear = parseInt(data.dataYear) + 543;
    DataInfoElements.dataTypeId = parseInt(data.dataTypeId);      
    DataInfoElements.dataType = data.dataType;  
    DataInfoElements.catalogId = parseInt(data.catalogId);      
    DataInfoElements.catalog = data.catalog;
    DataInfoElements.referTypeId = parseInt(data.referTypeId);    
    DataInfoElements.referType = data.referType;
    DataInfoElements.requestCode = data.requestCode;    
    DataInfoElements.reference = data.reference;
    DataInfoElements.requestDate = DateTime(data.requestDate);
    DataInfoElements.rows = data.rows;
    DataInfoElements.errorRows = data.errorRows;
    DataInfoElements.warningRows = data.warningRows;
    DataInfoElements.duplicateRows = data.duplicateRows;    
    // DataInfoElements.errorCount = data.errorCount;
    return DataInfoElements;
}

/** verify : 13066 */
export const SearchParams = (controlElements, SearchElements) => {
    let params, queryString = {};

    queryString["Init"] = controlElements.Init;
    queryString["Page"] = controlElements.Page;

    if( queryString["Init"] === 'Init'){
        for( let name in SearchElements){ queryString[name] = SearchElements[name].value; }    
    }
    params = {withCredentials: true, params: queryString};

    return params;
}

export const ResetInput = (InputElements) => { for (let name in InputElements){ InputElements[name].value = ''; InputElements[name].error = {status: false, msg: ''}; } return InputElements; }

/** verify : 13066 */
export const ValidatorInput = (value, rules) => {
    let valid = true;
    let msg = '';
    let length;

    if(value === '' || value === undefined){ value = ''; }
    value = String(value);
    value = value.trim();
    length = value.length;

    if(rules.type === 'number' && isNaN(value)){ valid = false; msg = '* กรุณากรอกเฉพาะคัวเลข'; }
    if(rules.required && length === 0 && valid){ valid = false; msg = '* จำเป็นต้องกรอก'; }        
    if(rules.minLength && length < rules.minLength && valid ){ valid = false; msg = '* ต้องการข้อมูลไม่น้อยกว่า ' + rules.minLength + ' ตัวอักษร';}
    if(rules.maxLength && length > rules.maxLength && valid ){ valid = false; msg = '* ต้องการข้อมูลไม่เกินกว่า ' + rules.maxLength + ' ตัวอักษร'; } 
    if(rules.pattern === 'email' && valid){ 
        if(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) === false){
            valid = false; 
            msg = 'รูปแบบอีเมล์ไม่ถูกต้อง';
        }
    }if(rules.pattern === 'tel' && valid){
        // console.log('hello');
        if( value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) ){
        }else{    
            valid = false; 
            msg = 'รูปแบบเบอร์ติดต่อไม่ถูกต้อง';            
        }
    }  
    return {status: !valid, msg: msg};
}

export const InputForm = (InputElements) => { let inputs = {}; for (let name in InputElements){ inputs[name] = InputElements[name].value;} return inputs; }

export const RandomPassword = () => { return Math.random().toString(36).slice(-8); }

/** verify : 170366 */
export const TriggerInputChange = (inputType, id, value) => {
    const input = document.querySelector('#' + id);
    if(inputType === 'text'){
        Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(input, value);
    }else if(inputType === 'select'){
        // Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set.call(input, value);        
    }
    input.dispatchEvent(new Event('change', { bubbles: true }));
}

/** verify : 170666 */
export const Notification = (toast, action, result, item) => {  
    let actionTxt;

    if(item === '' || item === undefined){ item = "ข้อมูล"; }
    else{ item = 'ข้อมูล'}

    if(action === 'Entry'){ actionTxt = "บันทึก";}
    else if(action === 'Edit'){ actionTxt = "ปรับปรุง";}
    else if(action === 'Delete'){ actionTxt = "ลบ";}
    else if(action === 'Status'){actionTxt = "ปรับสถานะ";}
    else if(action === 'ChangePassword'){
        if(result === 'not-match'){ actionTxt = "รหัสผ่าน และ ยืนยันรหัส ไม่ตรงกัน"; }
        else if(result === 'in-correct'){ actionTxt = "รหัสผ่านเดิมไม่ถูกต้อง"; }
        else if(result === 'success' || result === 'error'){ actionTxt = "เปลี่ยนรหัสผ่าน"; }
    }

    if(action !== 'ChangePassword'){ actionTxt += " " + item; }

    if(result === 'success'){ actionTxt += " เรียบร้อย"; }
    else if(result === 'error'){ actionTxt += " ไม่สำเร็จ"; }
    else if(result === 'warning'){ actionTxt += " ซ้ำซ้อน"; }  

    if(action === 'Delete'){
        if(result === 'success'){ toast.error(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }
    }else if(action === 'Entry' || action === 'Edit'){
        if(result === 'success'){ toast.success(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }
        else if(result === 'warning'){ toast.warning(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }
        else if(result === 'error'){ toast.error(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }
    }else if(action === 'Status'){
        if(result === 'success'){ toast.info(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }
    }else if(action === 'ChangePassword'){
        if( result === 'success'){ toast.success(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }
        else if(result === 'not-match'){ toast.warning(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }   
        else if(result === 'in-correct' || result === 'error'){ toast.error(actionTxt, {position: toast.POSITION.TOP_RIGHT}); }     
    }
    /** else if(result === 'i'){toast.info(msgTxt, {position: toast.POSITION.TOP_RIGHT});} */         
}

export const getRowDataIcon = (recordStatus) => {
    // console.log(recordStatus);
    let icon;
    if (recordStatus === RawDataStatus['0']){icon = (<i className="fa-solid fa-circle-check fa-fw fa-lg" style={{color: "#558b2f"}}></i>);}
    else if (recordStatus === RawDataStatus['1']){icon = (<i className="fa-solid fa-circle-exclamation fa-fw fa-lg" style={{color: '#ff8f16'}}></i>);}
    else if (recordStatus === RawDataStatus['2']){icon = (<i className="fa-solid fa-circle-info fa-fw fa-lg" style={{color: '#4527a0'}}></i>);}
    else if (recordStatus === RawDataStatus['3']){icon = (<i className="fa-solid fa-ban fa-fw fa-lg" style={{color: '#c62828'}}></i>);}
    else { icon = null;}
    return icon
}

export const getRowDataClassName = (params) => {
    let classApply = "";  
    /**
        const status = params.row.status;

        if(status === RawDataStatus['0']){classApply = "";}
        else if(status === RawDataStatus['1']){classApply = "row-duplicate";}
        else if(status === RawDataStatus['2']){classApply = "row-warning";}
        else if(status === RawDataStatus['3']){classApply = "row-error";}
     */
    return classApply;
}

export const DateTime = (datetime, timeFlag) => {
    // console.log(datetime);
    if(datetime !== '' && datetime !== undefined){
        let obj = datetime.split("T");

        let dmy = obj[0].split("-");
    
        let year = parseInt(dmy[0]) + 543;
        let month = dmy[1];
        let date = parseInt(dmy[2]);
        let time = "";
    
        if( obj.length > 1 ){time = obj[1].substring(0, 5);  }
    
        datetime = date + " " + MonthArray[month] + " " + year;
        if(timeFlag === '1' || timeFlag === 1){ datetime += " " + time; }
    }
    return datetime;
}

export const FormatNumber = (number) => { if(number !== '' && number !== undefined && number !== null){ number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");} return number; }

export const isObject = (variable) => { return typeof variable === 'object' && variable !== null; }

export const getCookieByName = (cookieName) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return '';
}   
export const getUserProfile = () => {
    let val = getCookieByName("ditUserProfile");
    if(val !== undefined && val !== ''){
        return JSON.parse(val);
    }
}

/**
export const getElementsSize = () => {
    let vh = 1;
    let val = getCookieByName("tfsUserProfile");
    if(val !== undefined && val !== ''){
        val = JSON.parse(val);
        if(val.font === '0'){
            vh = 1 - 0.2;
        }else if(val.font === '1'){
            vh = 1;
        }else if(val.font === '2'){
            vh = 1 + 0.15;
        }
        return vh + 'rem';;
    }else{ 
        return '';
    }
}
 */

export const getElementsSize = () => {
    return "md";
    /** 
    let vh = 1;
    let val = getCookieByName("tfsUserProfile");
    if(val !== undefined && val !== ''){
        val = JSON.parse(val);
        if(val.font === '0'){
            vh = 'sm';
        }else if(val.font === '1'){
            vh = 'md';
        }else if(val.font === '2'){
            vh = 'lg';
        }
        return vh;
    }else{ 
        return '';
    }
    */
}





